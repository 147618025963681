import React from 'react'
import Gallery from '../../../Components/admin/Gallery'
import Text from '../../../Components/admin/Text'
import Acor from '../../../Components/admin/Acor';
import MobileHeader from '../../../Components/admin/MobileHeader';
import { AiOutlinePlus } from 'react-icons/ai'
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import toast, { Toaster } from 'react-hot-toast';




const AdminProjects = () => {





    const [title, setTitle] = useState('')
    const [text, setText] = useState('')
    const [projects, setProjects] = useState([])


    const updateHandel = () => {
        const formdata = new FormData();
        formdata.append('action', 'update')
        formdata.append('tableName', 'projectspage')
        formdata.append('title', title)
        formdata.append('text', text)
        fetch(process.env.REACT_APP_BASE_URL + 'projectspage.php', {
            method: 'POST',
            body: formdata
        }).then(res => res.json()).then(res => {
            if (res.status != 200) {
                toast.error('Bir hata oluştu')
            }
            else {
                toast.success('Kaydedildi')
            }

        })
    }

    const dataFetch = () => {
        const formdata = new FormData()
        formdata.append('action', 'projects')

        const res = fetch(process.env.REACT_APP_BASE_URL + 'selectFrontdata.php', {
            method: 'POST',
            body: formdata
        }).then(res => res.json())
            .then(async (data) => {
                setTitle(data.title)
                setText(data.text)

            })


    }

    const selectProjects = () => {
        const formdata = new FormData()
        formdata.append('action', 'selectProject')
        fetch(process.env.REACT_APP_BASE_URL + 'selectFrontdata.php', {
            method: 'POST',
            body: formdata
        }).then(res => res.json()).then(res => {
            setProjects(res)
        })
    }

    const deleteHandel = (item) => {

        const formdata = new FormData()
        formdata.append('action', 'deleteProject')
        formdata.append('id', item.id)
        formdata.append('name', item.name)

        fetch(process.env.REACT_APP_BASE_URL + 'projects.php', {
            method: 'POST',
            body: formdata
        }).then(res => res.json()).then(res => {
            if (res.status != 200) {
                toast.error('Bir hata oluştu')
            }
            else {
                toast.success('Silindi')
            }
            selectProjects()
        })


    }

    useEffect(() => {
        document.title = 'Projeler'

        dataFetch()
        selectProjects()
        return () => {

        }
    }
        , [])

    return (
        <div className='w-screen h-screen overflow-scroll'>
            <Toaster position='top-left' />
            <MobileHeader title={'Projeler'} icon={<AiOutlinePlus size={32} className='text-white'></AiOutlinePlus>}></MobileHeader>
            <div className='w-full h-auto flex flex-col items-center justify-center p-2 gap-4 '>
                <Gallery title={'Arkaplan'}  name='bg' url={process.env.REACT_APP_BASE_URL + 'projectspage.php'} path={'projectspage/background'} tableName='projectspage' columnName='background'></Gallery>
                <Acor title={'Yazı alanı'}>
                    <Text title={'Sayfa başlığı'} func={setTitle} value={title}></Text>
                    <Text title={'Arkaplan yazısı'} func={setText} value={text}></Text>
                    <div className='w-full flex items-center justify-end p-3 '>
                        <button onClick={() => { updateHandel() }} className='btn bg-blue-600 text-white w-full md:w-1/2 lg:w-40 h-12 rounded p-2'>Kaydet</button>
                    </div>
                </Acor>

                <div className='w-full flex items-center justify-start p-3 hover:shadow-md rounded-md shadow-none shadow-black transition-all duration-500'>
                    <p className='text-3xl tracking-[2px]'>Projeler</p>
                </div>
                <div className='w-full flex items-center justify-center gap-4 '>
                    <Text title={'İsime göre ara'} ></Text>
                    <Link to={`/dashboard/addProject`} className=' bg-blue-600 w-12 h-12 text-white rounded-full flex items-center justify-center group  hover:w-32  transition-all'>
                        <AiOutlinePlus size={20}></AiOutlinePlus>
                        <p className='hidden group-hover:block'>Ekle</p>
                    </Link>
                </div>
                <div className='w-full p-4 grid md:grid-cols-2 grid-cols-1 lg:grid-cols-4 h-auto min-h-full gap-2 '>
                    {
                        projects.map((item, index) => {
                            return (
                                <div key={index} className='w-full gap-1 h-full flex flex-col items-start justify-start p-2 shadow-sm shadow-black/50'>
                                    <div
                                        style={{ backgroundImage: `url(${process.env.REACT_APP_UPLOADS_URL}projects/${item.name}/banner/${item.banner})` }}
                                        className='aspect-square relative w-full text-white  bg-center bg-cover '>
                                        {
                                            <p className='absolute flex items-center justify-start px-2 left-0 bottom-0 w-full h-10 bg-black/30'>
                                                {item.name}
                                            </p>
                                        }
                                    </div>
                                    <div className='w-full gap-4 h-16 flex items-center justify-between'>
                                        <button onClick={() => { deleteHandel(item) }} className='w-full bg-red-500 hover:bg-red-400 h-full rounded-sm text-white text-xl'>Sil</button>
                                        <Link to={'/dashboard/edit/'+item.id} className='w-full flex items-center justify-center bg-indigo-500 hover:bg-indigo-400 h-full rounded-sm text-white text-xl'>Düzenle</Link>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>

            </div>


        </div>
    )
}

export default AdminProjects