import React from 'react'
import AdminNav from '../../Components/admin/AdminNav'
import { Outlet } from 'react-router-dom'

const Dashboard = () => {
  return (
    <div className='w-full min-h-screen flex items-start justify-start relative '>
        <AdminNav></AdminNav>
        <Outlet></Outlet>
    </div>
  )
}

export default Dashboard