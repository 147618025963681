import React, { useEffect } from 'react'
import { AiOutlinePicture } from 'react-icons/ai'
import MobileHeader from '../../../Components/admin/MobileHeader'
import { useState } from 'react'
import Gallery from '../../../Components/admin/Gallery'

const AdminLogo = () => {
    const [logo, setLogo] = useState('')
    const [file, setFile] = useState('')
    useEffect(() => {
        document.title = 'Logo'
        const formdata = new FormData()
        formdata.append('action', 'select')
        fetch(process.env.REACT_APP_BASE_URL + 'logo.php', {
            method: 'POST',
            body: formdata
        }).then(res => res.json()).then(res => {
           setLogo(res.path)
        })




    }, [])


    const submitHandel=()=>{
        const formdata = new FormData()
        
        for(let i=0;i<file.length;i++){
            formdata.append('logo[]',file[i])
        }
        
        fetch(process.env.REACT_APP_BASE_URL + 'logo.php', {
            method: 'POST',
            body: formdata
        }).then(res => res.json()).then(res => {
            console.log(res)
        })
    }

  return (
    <div className='w-screen h-screen overflow-scroll relative'>
      <MobileHeader title={'Logo Ayarı'} icon={<AiOutlinePicture size={32} className='group-hover:text-black text-white' />}></MobileHeader>
       
        <Gallery title={'Logo'} folder={'logo'} split={false} name={'logo'} url={process.env.REACT_APP_BASE_URL+'logo.php'} path={'logo'} columnName={'path'} limit={1} tableName={'logo'}></Gallery>
    </div>
  )
}

export default AdminLogo