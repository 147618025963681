import React, { useEffect } from 'react'
import Gallery from '../../../Components/admin/Gallery'
import Text from '../../../Components/admin/Text'
import Acor from '../../../Components/admin/Acor';
import { useState } from 'react';
import { AiOutlineHome } from 'react-icons/ai'
import toast, { Toaster } from 'react-hot-toast';
import MobileHeader from '../../../Components/admin/MobileHeader';


const AdminHome = () => {
  const [title, setTitle] = useState('')
  const [sliderTitle, setsliderTitle] = useState('')
  const [sliderText, setsliderText] = useState('')
  const [bannerTitle, setbannerTitle] = useState('')
  const [link, setlink] = useState('')
  const [sideTitle, setsideTitle] = useState('')
  const [sideText, setsideText] = useState('')
  const [bottomSliderTitle, setbottomSliderTitle] = useState('')


  const updateHandel = () => {
    const formdata = new FormData();
    formdata.append('action', 'update')
    formdata.append('tableName', 'home')
    formdata.append('title', title)
    formdata.append('sliderTitle', sliderTitle)
    formdata.append('sliderText', sliderText)
    formdata.append('bannerTitle', bannerTitle)
    formdata.append('sideTitle', sideTitle)
    formdata.append('sideText', sideText)
    formdata.append('bottomSliderTitle', bottomSliderTitle)
    formdata.append('link', link)

    fetch(process.env.REACT_APP_BASE_URL + 'home.php', {
      method: 'POST',
      body: formdata
    }).then(res => res.json()).then(res => {
      if(res.status != 200){
        toast.error('Bir hata oluştu')
      }else{
        toast.success('Kaydedildi')
      }
    })


  }
  useEffect(() => {
    document.title = 'Anasayfa'
    const formdata = new FormData();
    formdata.append('action', 'selectHome')


    fetch(process.env.REACT_APP_BASE_URL + 'home.php', {
      method: 'POST',
      body: formdata
    }).then(res => res.json()).then(res => {
      setlink(res.link)
      setTitle(res.title)
      setsliderTitle(res.sliderTitle)
      setsliderText(res.sliderText)
      setbannerTitle(res.bannerTitle)
      setsideTitle(res.sideTitle)
      setsideText(res.sideText)
      setbottomSliderTitle(res.bottomSliderTitle)


    })


  }, [])


  return (

    <div className='w-screen h-screen overflow-scroll relative'>
      <Toaster position='top-left' />
      <MobileHeader title={'Anasayfa'} icon={<AiOutlineHome size={32} className='group-hover:text-black text-white' />}></MobileHeader>
      <div className='w-full h-auto flex flex-col items-center justify-center p-2 gap-4 '>
        <Text func={setTitle} value={title} title={'Sayfa başlığı'} name='title'></Text>

        <Gallery title={'Arka Planlar'} name='bg' url={process.env.REACT_APP_BASE_URL + 'home.php'} path={'home/background'} tableName='home' columnName='sliderBackground' >

        </Gallery>
        <Gallery title={'Kartlar'} tableName='home' name='card' url={process.env.REACT_APP_BASE_URL + 'home.php'} path={'home/card'} columnName='cardBackground' limit='2'  >

        </Gallery>
        <Gallery title={'Slayt'} tableName='home' name='bottomSlayt' url={process.env.REACT_APP_BASE_URL + 'home.php'} path={'home/bottomSlayt'} columnName='bottomSlayt' limit='1000'  >

        </Gallery>

        <Acor title={'Yazı alanları'} >
          <div className='w-full h-auto flex flex-col items-center justify-center gap-4 '>
            <Acor title={'Banner'}>
              <Text func={setsliderTitle} value={sliderTitle} title={'Banner başlık'}></Text>
              <Text func={setsliderText} value={sliderText} title={'Banner info'}></Text>
            </Acor>
            <Acor title={'Hakkımızda yazıları'}>
              <Text func={setbannerTitle} value={bannerTitle} title={'Hakkımızda başlık'}></Text>
              <Text func={setsideTitle} value={sideTitle} title={'Hakkımızda içerik'}></Text>
              <Text func={setsideText} value={sideText} title={'Hakkımızda içerik'}></Text>

            </Acor>
            <Acor title={'Slayt gösterisi yazıları'}>
              <Text func={setbottomSliderTitle} value={bottomSliderTitle} title={'Slogan yazısı'}></Text>
             
            </Acor>

          </div>
          
        </Acor>
        <Acor title={'Youtube'}>
            <Text func={setlink} value={link} title={'Lütfen video adresini yazın'}></Text>
        </Acor>
        <div className='w-full flex items-center justify-end py-2'>
            <button onClick={() => { updateHandel() }} className='bg-blue-600 p-4 lg:w-32 rounded-md w-full  text-white'>Kaydet</button>
          </div>


      </div>

    </div>

  )
}

export default AdminHome