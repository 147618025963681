import React from 'react'
import Gallery from '../../../Components/admin/Gallery'
import Text from '../../../Components/admin/Text'
import Acor from '../../../Components/admin/Acor';
import MobileHeader from '../../../Components/admin/MobileHeader';
import {AiOutlineInfoCircle} from 'react-icons/ai'
import { useState,useEffect } from 'react';
import toast, { Toaster } from 'react-hot-toast';


export const AdminAbout = () => {

  const [title, setTitle] = useState('')
  const [backgroundTitle, setBackgroundTitle] = useState('')
  const [sideHeader, setSideHeader] = useState('')
  const [sideTitle, setSideTitle] = useState('')
  const [sideText, setSideText] = useState('')
  const [lsideHeader, setLsideHeader] = useState('')
  const [lsideTitle, setLsideTitle] = useState('')
  const [lsideText, setLsideText] = useState('')
  const [sliderSlogan, setSliderSlogan] = useState('')
  const [compName, setCompName] = useState('')


  const updateHandel = () => {

    const formdata = new FormData();
    formdata.append('action', 'update')
    formdata.append('tableName', 'about')
    formdata.append('title', title)
    formdata.append('backgroundTitle', backgroundTitle)
    formdata.append('sideHeader', sideHeader)
    formdata.append('sideTitle', sideTitle)
    formdata.append('sideText', sideText)
    formdata.append('lsideHeader', lsideHeader)
    formdata.append('lsideTitle', lsideTitle)
    formdata.append('lsideText', lsideText)
    formdata.append('sliderSlogan', sliderSlogan)
    formdata.append('compName', compName)
    fetch(process.env.REACT_APP_BASE_URL + 'about.php', {
      method: 'POST',
      body: formdata
    }).then(res => res.json()).then(res => {
      if(res.status != 200){
        toast.error('Bir hata oluştu')
      }else{
        toast.success('Kaydedildi')
      }
    })


  }

  const dataFetch = async () => {
    const formdata = new FormData();
    formdata.append('action', 'selectAbout')
    fetch(process.env.REACT_APP_BASE_URL + 'about.php', {
      method: 'POST',
      body: formdata
    }).then(res => res.json()).then(res => {
      setTitle(res.title)
      setBackgroundTitle(res.backgroundTitle)
      setSideHeader(res.sideHeader)
      setSideTitle(res.sideTitle)
      setSideText(res.sideText)
      setLsideHeader(res.lsideHeader)
      setLsideTitle(res.lsideTitle)
      setLsideText(res.lsideText)
      setSliderSlogan(res.sliderSlogan)
      setCompName(res.compName)
    })
  }

  useEffect(() => {
    document.title = 'Hakkımızda'
    dataFetch()
    return () => {
      console.log('cleaned up')
    }
   
  }
    , [])


  return (
    <div className='w-screen h-screen overflow-scroll'>
      <Toaster position='top-right' />
      <MobileHeader title={'Hakkımızda'} icon={<AiOutlineInfoCircle size={32} className='text-white'></AiOutlineInfoCircle>}></MobileHeader>
    <div className='w-full h-auto flex flex-col items-center justify-center p-2 gap-4 '>
    <Text func={setTitle} value={title} title={'Sayfa başlığı'}></Text>
     <Gallery title={'Arka Plan'} name='bg' url={process.env.REACT_APP_BASE_URL + 'about.php'} path={'about/background'} tableName='about' columnName='background'></Gallery>
     <Acor title={'Kartlar'}>
     <Gallery title={'Kart Alanı'} name='card' url={process.env.REACT_APP_BASE_URL + 'about.php'} path={'about/card'} tableName='about' columnName='sideBackground'></Gallery>
     <Gallery title={'Ters Kart Alanı'} name='lcard' url={process.env.REACT_APP_BASE_URL + 'about.php'} path={'about/lcard'} tableName='about' columnName='lsideBackground'></Gallery>
      </Acor>
     <Acor  title={'Yazı alanları'} >
     
              <Text title={'Arkaplan başlık'} func={setBackgroundTitle} value={backgroundTitle}></Text>
              <Text title={'Firma Adı'} func={setCompName} value={compName}></Text>
           <Acor title={'Kart yazıları'}>
               <Text title={'Başlık'} func={setSideTitle} value={sideTitle}></Text>
               <Text title={'Alt başlık'} func={setSideHeader} value={sideHeader}></Text>
               <Text title={'Yan başlık'} func={setSideText} value={sideText}></Text>
           </Acor>
           <Acor title={'2.Kart yazıları'}>
               <Text title={'Başlık'} func={setLsideTitle} value={lsideTitle}></Text>
               <Text title={'Yan başlık'} func={setLsideHeader} value={lsideHeader}></Text>
               <Text title={'Alt başlık'} func={setLsideText} value={lsideText}></Text>
              
           </Acor>
           <Acor title={'Slayt gösterisi yazıları'}>
             <Text title={'Slogan yazısı'} func={setSliderSlogan} value={sliderSlogan}></Text>
           </Acor>
          
        
       </Acor>
       <Gallery title={'Ara resim'} name={'banner'} url={process.env.REACT_APP_BASE_URL+'about.php'} path={'about/banner'} tableName={'about'} columnName={'banner'}></Gallery>
       <Gallery title={'Slayt gösterisi'} name={'aboutSlider'} path={'about/slider'} url={process.env.REACT_APP_BASE_URL+'about.php'} tableName={'about'} columnName={'aboutSlider'}></Gallery>
       <div className='w-full flex items-center justify-end py-2'>
            <button onClick={() => { updateHandel() }} className='bg-blue-600 p-4 lg:w-32 rounded-md w-full  text-white'>Kaydet</button>
       </div>
   </div>
   
   
 </div>
  )
}
