import React from 'react'

const Mailbox = ({data}) => {
  return (
    <div className='w-full min-h-[10rem] flex flex-col items-start justify-start p-2 bg-slate-100 shadow shadow-black/40 rounded'>
        <div className='w-full flex items-center px-4 justify-between'>
            <span className='flex items-center justify-center gap-2.5'>
            <h1 className='text-lg capitalize flex items-center justify-center gap-3'>{data.name}</h1>
            <h1 className='text-lg uppercase flex items-center justify-center gap-3'>{data.surname}</h1>
            </span>
            <h1>{data.fromMail}</h1>
            <h1 className=''>{data.date}</h1>
           
        </div>
        <p className='w-full px-6 py-4'>{data.message}</p>
        
    </div>
  )
}

export default Mailbox