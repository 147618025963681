import React, { useEffect, useMemo } from 'react'
import Navbar from '../Components/Navbar'
import HalfBanner from '../Components/HalfBanner'
import Project from '../Components/Project'
import Translate from '../Helper/Translate'
import { useSelector } from 'react-redux'

const Projects = () => {

  
  const code = useSelector(state => state.Language.language)
  const [background,setBackground] = React.useState('')
  const [title,setTitle] = React.useState('')
  const [text,setText] = React.useState('')
  const [projects,setProjects] = React.useState([])

  const dataFetch =  () => {
    const formdata = new FormData()
    formdata.append('action','projects')
    
   const res =   fetch(process.env.REACT_APP_BASE_URL+'selectFrontdata.php',{
      method:'POST',
      body:formdata
    }).then(res => res.json())
    .then(async(data)=>{
      document.scroll = 0
      setBackground(data.background)
      setTitle(data.title)
      setText(data.text)
      document.title = 'Projelerimiz'
      if( code =='tr'){
        setBackground(data.background)
        setTitle(data.title)
        setText(data.text)
      }else{
        const pageTitle = await Translate('Projelerimiz', 'tr', localStorage.getItem('language'))
        document.title = pageTitle.data.translations[0].translatedText
        const Ti = await Translate(data.title,'tr',code)
        const Te = await Translate(data.text,'tr',code)
        setTitle(Ti.data.translations[0].translatedText)
        setText(Te.data.translations[0].translatedText)
        

      }

    })
    
   
  
  }

  const selectProjects = ()=>{
    const formdata = new FormData();
    formdata.append('action','selectProject')
    fetch(process.env.REACT_APP_BASE_URL+'selectFrontdata.php',{
      method:'POST',
      body:formdata
    }).then(res=>res.json()).then(res=>{
      setProjects(res)
    })
  }
  useMemo(() => {
    dataFetch()
    
    return () => {
      
    }
  }, [code])
  useEffect(() => {
    selectProjects()
    return () => {
      
    }
  }, [])

  return (
    <div className='w-full min-h-screen flex flex-col relative  items-center bg-slate-50'>
      <Navbar></Navbar>
      <HalfBanner img={process.env.REACT_APP_BASE_URL+'uploads/projectspage/background/'+ background} text={text}  ></HalfBanner>
      <Project data={projects}></Project>
    </div>
  )
}

export default Projects