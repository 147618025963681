import React from 'react'
import { Listbox } from '@headlessui/react'
import { useState } from 'react'
import {BiWorld} from 'react-icons/bi'
import { useDispatch , useSelector } from 'react-redux';
import { chancheLanguage } from '../Reducers/Language';




const Dil = () => {


    const currentCode = useSelector(state => state.Language.language)
    const dispatch = useDispatch()


    const language = [
      { id: 1, name: 'Türkçe', code: 'tr' },
      { id: 2, name: 'English', code: 'en' },
      { id: 3, name: 'Deutsch', code: 'de' },
      { id: 4, name: 'Français', code: 'fr' },
      { id: 5, name: 'العربية', code: 'ar' },
      { id: 6, name: 'Русский', code: 'ru' },
      { id: 7, name: 'Español', code: 'es' },
      { id: 8, name: 'Italiano', code: 'it' },
      { id: 9, name: '中文', code: 'zh' },
      { id: 10, name: '日本語', code: 'ja' },
      { id: 11, name: 'Português', code: 'pt' },
      { id: 12, name: 'Nederlands', code: 'nl' },
      { id: 13, name: '한국어', code: 'ko' },
      { id: 14, name: 'Svenska', code: 'sv' },
      { id: 15, name: 'Dansk', code: 'da' },
      { id: 16, name: 'Ελληνικά', code: 'el' },
      { id: 17, name: 'Norsk', code: 'no' },
      { id: 18, name: 'Suomi', code: 'fi' },
      { id: 19, name: 'Polski', code: 'pl' },
      { id: 20, name: 'Čeština', code: 'cs' },
      { id: 21, name: 'Magyar', code: 'hu' },
      { id: 22, name: 'Türkmençe', code: 'tk' },
      { id: 23, name: 'ไทย', code: 'th' },
      { id: 24, name: 'Hindi', code: 'hi' },
      { id: 25, name: 'Bahasa Indonesia', code: 'id' },
      { id: 26, name: 'Slovenčina', code: 'sk' },
      { id: 27, name: 'Українська', code: 'uk' },
      { id: 28, name: 'Ελληνικά', code: 'el' },
      { id: 29, name: 'Magyar', code: 'hu' },
      { id: 30, name: 'Türkmençe', code: 'tk' },
      { id: 31, name: 'ไทย', code: 'th' },
      { id: 32, name: 'Hindi', code: 'hi' },
      { id: 33, name: 'Bahasa Indonesia', code: 'id' },
      { id: 34, name: 'Slovenčina', code: 'sk' },
      { id: 35, name: 'Українська', code: 'uk' },
      { id: 36, name: 'Slovenščina', code: 'sl' },
      { id: 37, name: 'עברית', code: 'he' },
      { id: 38, name: 'Български', code: 'bg' },
      { id: 39, name: 'Română', code: 'ro' },
      { id: 40, name: 'Svenska', code: 'sv' },
      { id: 41, name: 'Kurdî', code: 'ku' },
      { id: 42, name: 'Bangladesh', code: 'bn' },
      
    ];
    

      const [selectLanguage, setSelectLanguage] = useState(language[0])

      const languageHandel = (code) => {
        dispatch(chancheLanguage(code))
       
      }

  return (
   <>
   
    <Listbox className='flex  flex-col lg:ml-4 relative' as={'div'} onChange={setSelectLanguage}>
      <Listbox.Button className={'md:text-white text-black hover:text-blue-600 gap-1 flex items-center justify-center'}>{
        <BiWorld size={32}  className=""/> 
        
      } {
        <p className='uppercase'>
          {
            currentCode
          }
          </p>
      }      
      </Listbox.Button>
      <Listbox.Options className={'absolute max-h-80 lan overflow-y-scroll top-8 p-3 flex flex-col gap-2 whitespace-nowrap rounded-sm list bg-white left-0 shadow-sm shadow-black/30 lg:shadow-black/50 lg:shadow-lg'}>
        {language.map((text) => (
          <Listbox.Option
            key={text.id}
            value={text}
            onClick={()=>{languageHandel(text.code)}}
            className="cursor-pointer text-center hover:bg-gray-100 p-2 rounded-sm"

          >
            {text.name}
          </Listbox.Option>
        ))}
      </Listbox.Options>
    </Listbox>
    
    
   </>
  )
}

export default Dil