import React from 'react'
import { GiHamburgerMenu } from 'react-icons/gi'
import { RxCross1 } from 'react-icons/rx'
import { BiHome } from 'react-icons/bi'
import {AiOutlineInfoCircle} from 'react-icons/ai'
import { FaSitemap } from "react-icons/fa";
import { TbReportAnalytics } from "react-icons/tb";
import { CiMail } from "react-icons/ci";
import { SlPicture } from "react-icons/sl";

import {BsTelephoneFill} from 'react-icons/bs'
import { Link } from 'react-router-dom'
import {useRef } from 'react'



const MobileHeader = ({ title, icon }) => {
  const mobile = useRef()
  const toggleHandel = () => {
    mobile.current.classList.toggle('hidden')
  }
  return (
    <div className='w-full h-20 bg-slate-600 flex items-center lg:justify-end justify-between p-4 md:px-12 '>
      <GiHamburgerMenu onClick={()=>{toggleHandel()}} size={32} className='text-white lg:hidden' />
      <span className='flex items-center justify-center gap-2'>
        {
          icon
        }
        <h1 className='text-white text-3xl uppercase tracking-wide antialiased font-light'>
          {
            title
          }
        </h1>
      </span>
      <div

        ref={mobile}
       
        className='hidden lg:hidden w-full h-screen bg-black/50 fixed top-0 left-0 z-50 flex items-center justify-center'
      >
        <div className='w-full h-full bg-white flex flex-col items-start px-12 justify-center space-y-12'>
          <RxCross1 size={32} className='absolute top-4 right-4 text-black hover:text-blue-600' onClick={() => mobile.current.classList.toggle('hidden')} />
          <Link to='/dashboard' className='text-black text-2xl hover:text-blue-800 flex items-center justify-center gap-2'>
            <BiHome></BiHome>
            Anasayfa ayarları
          </Link >
          <Link to='/dashboard/about' className='text-black text-2xl hover:text-blue-800 flex items-center justify-center gap-2'>
            <AiOutlineInfoCircle/>
            Hakkımızda ayarları
          </Link >
          <Link to='/dashboard/contact' className='text-black text-2xl hover:text-blue-800 flex items-center justify-center gap-2'>
            <BsTelephoneFill></BsTelephoneFill>
            İletişim ayarları
          </Link >
          <Link to='/dashboard/add' className='text-black text-2xl hover:text-blue-800 flex items-center justify-center gap-2'>
            <FaSitemap></FaSitemap>
            Projeler
          </Link >
          <Link to='/dashboard/statistics' className='text-black text-2xl hover:text-blue-800 flex items-center justify-center gap-2'>
            <TbReportAnalytics></TbReportAnalytics>
            İstatislikler
          </Link >
          <Link to='/dashboard/mail' className='text-black text-2xl hover:text-blue-800 flex items-center justify-center gap-2'>
            <CiMail></CiMail>
            Mailler
          </Link >
          <Link to='/dashboard/logo' className='text-black text-2xl hover:text-blue-800 flex items-center justify-center gap-2'>
            <SlPicture></SlPicture>
            Logo ayarları
          </Link >
          
            

        </div>
      </div>
    </div>
  )
}

export default MobileHeader