import React, { useEffect, useMemo, useState } from 'react'
import { Accordion, AccordionTab } from 'primereact/accordion';
import {İmgCard} from '../Cards/İmgCard'        
import Modal from 'react-modal';
import { FileUpload } from 'primereact/fileupload';
import { Toast } from 'primereact/toast';
import toast from 'react-hot-toast';
const Gallery = ({title,name,url,path,columnName,limit,tableName,split=true}) => {

    const file=[0,1,2,3,4,5,6,7,8,9,10];
    const [modalIsOpen,setIsOpen] = React.useState(false);
    const [files,setFiles] = React.useState([]);
    const [data,setData] = useState([])
    useMemo(()=>{

      if(files.length>0){
        setIsOpen(false)
      }
    },[files])

    useEffect(()=>{
      const formdata = new FormData();
      formdata.append('action','select')
      formdata.append('tableName',tableName)
      formdata.append('columnName',columnName)
      

      fetch(url,{
        method:'POST',
        body:formdata
      }).then(res=>res.json()).then(res=>{
        
      
      if(split){
        res && setData(res.replace('"','').replace('"','').split(','))
      }else{
        setData(res)
      }
      

        
        
        
        
      })
    }
    ,[])
   

  return (
        <div className='w-full'>
          <Toast position='top-right' />
          <Accordion activeIndex={null}>
             <AccordionTab header={title} className='p-0'>
                <div className='w-full flex items-center justify-end p-3 gap-4  '>
                  
                  <button className='btn bg-blue-600 text-white w-full md:w-1/2 lg:w-40 h-12 rounded p-2' onClick={()=>{setIsOpen(true)}}>Yeni Ekle</button>
                </div>
                <div className='w-full h-auto gap-4 grid lg:grid-cols-6 md:grid-cols-4 sm:grid-cols-3  xs:grid-cols-2 '>
                   {
                    split == true ? 
                      data.map((item,index)=>{
                         return(
                           <div key={index} className='w-full h-full flex items-center justify-center'>
                             <İmgCard path={path} item={item}/>
                           </div>
                         )
                       }):<div  className='w-full h-full flex items-center justify-center'>
                       <İmgCard  path={'logo/'} item={data.path}  />
                     </div>
                       
                     
                   }
                </div>
                
              </AccordionTab>
          </Accordion>
          <Modal ariaHideApp={true} isOpen={modalIsOpen} className='lg:w-1/2 w-3/4  h-auto pt-20 flex flex-col items-end justify-center top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 absolute bg-transparent rounded-lg'>
          <div className='w-full flex items-center justify-end'>
          <button onClick={()=>{setIsOpen(false)}} className='btn bg-red-600 text-white rounded-full w-8 h-8 flex items-center justify-center '>X</button>
          </div>
          <div className='w-full overflow-scroll'>
          <FileUpload onError={()=>{toast.error('Bir hata oldu')}} name={name+'[]'} limit={limit} url={url} className='w-full' multiple accept="image/*" maxFileSize={1000000*15} emptyTemplate={<p className="w-full md:w-1/2">Dosyalrı seçin veya sürükleyin</p>} />
          </div>

          </Modal>
         
        </div>


  )
}

export default Gallery