import React, { useRef } from 'react'

export const İmgCard = ({item,path}) => {
  
  const card = useRef();
  const folder = path.split('/')[0]
  const delteHandel=(item)=>{
   const formdata = new FormData();
    formdata.append('action','deleteİmage')
    formdata.append('name',item)
    formdata.append('path',path)
    fetch(process.env.REACT_APP_BASE_URL+folder+'.php',{
      method:'POST',
      body:formdata
    }).then(res=>res.json()).then(res=>{
      card.current.remove()
    })
  console.log(item)

  }
  return (
    <div ref={card} className='flex flex-col items-center justify-center gap-4 p-2 shadow-xl shadow-black/30 rounded'>
        <div 
        style={{backgroundImage:`url(${process.env.REACT_APP_UPLOADS_URL+path+'/'+item})`}}
        className='w-40 aspect-square default rounded'>

        </div>
        <div className='flex w-full items-center justify-between gap-2'>
            <button onClick={(e)=>{delteHandel(item)}} className='btn text-white flex-1 h-12 bg-red-600 flex items-center justify-center gap-2'>
                Sil
            </button>
          
        </div>
    </div>
  )
}
