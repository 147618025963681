import React, { useMemo, useState } from 'react'
import Navbar from '../Components/Navbar'
import { useParams } from 'react-router-dom'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation, Pagination } from 'swiper/modules';
import Footer from '../Components/Footer';
import { useEffect, useRef } from 'react';
import Translate from '../Helper/Translate';
import { useSelector } from 'react-redux';
export const ProjectDetail = () => {
  const code = useSelector(state => state.Language.language)
  const { id } = useParams()
  const [slayt, setSlayt] = useState([])
  const [name, setName] = useState('')
  const [title, setTitle] = useState('')
  const [subtitle, setSubtitle] = useState('')
  const [link, setLink] = useState('')
  const [kart, setKart] = useState('')
  const [galeri, setGaleri] = useState('')
  const [back, setBack] = useState('')
  const [banner, setBanner] = useState('')
  const mainContentRef = useRef()
  const midleContentRef = useRef()
  const bottomContentRef = useRef()
  const [l, setL] = useState(false)
  const [data, setData] = useState([])



  const maindata= (res)=>{
  
    mainContentRef.current.innerHTML = res['mainContent']
    midleContentRef.current.innerHTML = res['midleContent']
    bottomContentRef.current.innerHTML = res['bottomContent']

  }

  const fetchProject = () => {
    const formdata = new FormData()
    formdata.append('action', 'selectProjectById')
    formdata.append('id', id)
    fetch(process.env.REACT_APP_BASE_URL + 'selectFrontdata.php', {
      method: 'POST',
      body: formdata
    }).then(res => res.json()).then(res => {


      setSlayt(res['slayt'].split(','))
      setBanner(res['banner'])
      setKart(res['kart'])
      setL(res['link'].includes('http') ? true : false)
      setLink(res['link'])
      setGaleri(res['galeri'].split(','))
      setBack(res['arkaplan'])
      setName(res['name'])
      setTitle(res['title'])
      setSubtitle(res['subtitle'])
    
      maindata(res)
      document.scroll = 0


      setData(res)



    })
  }




  useMemo(async () => {

    if (code != 'tr') {
      const T = await Translate(data['name'], 'tr', code)
      const St = await Translate(data['subtitle'], 'tr', code)
      const Mc = await Translate(data['mainContent'], 'tr', code)
      const Mic = await Translate(data['midleContent'], 'tr', code)
      const Bc = await Translate(data['bottomContent'], 'tr', code)

      setTitle(T.data.translations[0].translatedText)
      setSubtitle(St.data.translations[0].translatedText)
      mainContentRef.current.innerHTML = Mc && Mc.data.translations[0].translatedText
      midleContentRef.current.innerHTML = Mic && Mic.data.translations[0].translatedText
      bottomContentRef.current.innerHTML = Bc && Bc.data.translations[0].translatedText
    } else {
      setTitle(data['name'])
      setSubtitle(data['subtitle'])



      const m = await mainContentRef.current;
      maindata(data)




    }

  }, [code,id])
  useEffect(() => {
    fetchProject()
    return (() => { })
  }, [id])



  return (
    <div className='w-full min-h-fit flex flex-col relative  items-center '>
      <Navbar back={true} ></Navbar>

      <div className='w-full flex-1 h-full lg:pt-20 gap-4 flex flex-col md:flex-row items-center justify-between '>
        <div className='lg:w-1/2 w-full h-[100vh]'>
          <Swiper
            spaceBetween={50}
            slidesPerView={1}
            modules={[Navigation, Pagination]}
            pagination={{ clickable: true }}

            className='w-full h-full'
          >
            {
              Object.keys(slayt).map((item, index) => {
                return (
                  <SwiperSlide key={index}>
                    <div
                      style={{ backgroundImage: `url(${process.env.REACT_APP_BASE_URL}uploads/projects/${name}/slayt/${slayt[item]})` }}
                      className='w-full h-full default bg-center bg-cover bg-no-repeat text-white  flex items-center justify-center' />
                  </SwiperSlide>
                )
              }
              )
            }


          </Swiper>


        </div>
        <div className='lg:w-1/2 w-full  h-[100vh] flex flex-col items-start lg:items-center justify-start lg:justify-start py-4 '>
          <div className='w-full flex items-center md:justify-start justify-center lg:px-0 px-4'>
            <h1 className=' text-left text-5xl tracking-[.5rem] uppercase font-medium py-2 border-b-2 border-black'>
              {name}
            </h1>
          </div>
          <div className=' w-full  py-4 flex flex-col  gap-4 '>
            <p className='text-4xl  w-full text-right px-4'>
              {title && title}
            </p>
            <p className='text-2xl  w-full text-right px-4'>
              {subtitle && subtitle}
            </p>

          </div>
          <p ref={mainContentRef} className='w-full  text-left px-2 overflow-y-scroll '>

          </p>
        </div>
      </div>
      <div className={'w-full h-[50vh] bg-center !bg-black/30 bg-no-repeat bg-cover relative flex items-center justify-center'}
        style={{ backgroundImage: `url(${process.env.REACT_APP_BASE_URL}uploads/projects/${name}/banner/${banner})` }}
      >

      </div>
      <div className='w-full flex-col flex lg:flex-row-reverse items-center justify-center h-screen lg:h-[75vh]  gap-5'>
        <div
          style={{ backgroundImage: `url(${process.env.REACT_APP_BASE_URL}uploads/projects/${name}/kart/${kart})` }}
          className='w-full bg-center bg-cover flex items-center justify-center h-full bg-white'>

        </div>
        <div ref={midleContentRef} className='w-full flex p-1 items-center justify-center h-full overflow-y-scroll bg-white'>


        </div>
      </div>
      <div className='w-full h-[50vh]  flex items-center justify-center'>
        <Swiper
          spaceBetween={0}
          slidesPerView={4}
          modules={[Navigation, Pagination]}
          pagination={{ clickable: true }}
          breakpoints={
            {
              0: {
                slidesPerView: 1,
              },
              640: {
                slidesPerView: 2,
              },
              768: {
                slidesPerView: 3,
              },
              1024: {
                slidesPerView: 4,
              },
            }
          }

          className='w-full h-full !flex items-center justify-center'
        >

          {
            Object.keys(galeri).map((item, index) => {
              return (
                <SwiperSlide key={index}>
                  <div
                    style={{ backgroundImage: `url(${process.env.REACT_APP_BASE_URL}uploads/projects/${name}/galeri/${galeri[item]})` }}
                    className='w-full bg-center bg-cover h-full bg-black text-white default flex items-center justify-center' />
                </SwiperSlide>
              )
            }
            )
          }

        </Swiper>

      </div>
      {
        l ? <div className='w-full flex items-center h-screen justify-center'>


          <iframe width="100%"
            height="100%"
            src={link}
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen>

          </iframe>


        </div> : null
      }
      <div
        style={{ backgroundImage: `url(${process.env.REACT_APP_BASE_URL}uploads/projects/${name}/arkaplan/${back})` }}
        ref={bottomContentRef}
        className='w-full text-white flex relative items-center justify-center h-[65vh] default px-12 tracking-widest text-center'>

      </div>

      <Footer></Footer>

    </div>
  )
}
