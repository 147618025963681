import React, { useEffect, useMemo, useRef } from 'react'
import {AiOutlineDown} from 'react-icons/ai'
import { Link } from 'react-router-dom'
import { motion } from 'framer-motion'
import{GiHamburgerMenu} from 'react-icons/gi'
import { RxCross1 } from 'react-icons/rx'
import {BiHome} from 'react-icons/bi'
import {AiOutlineInfoCircle} from 'react-icons/ai'
import {MdOutlineHomeWork} from 'react-icons/md'
import {BsTelephoneFill} from 'react-icons/bs'
import Dil from './Dil'
import Translate from '../Helper/Translate'
import { useSelector } from 'react-redux'
const Navbar = ({back}) => {

    const code = useSelector(state => state.Language.language)

    const mobile = useRef()
    const [home, setHome] = React.useState('Anasayfa')
    const [about, setAbout] = React.useState('Hakkımızda')
    const [projects, setProjects] = React.useState('Projelerimiz')
    const [contact, setContact] = React.useState('İletişim')
    const [data,setData]= React.useState([])
    const [logo,setLogo] = React.useState('')
    useEffect(() => {
        const formdata2 = new FormData()
        formdata2.append('action','select')
    
        fetch(process.env.REACT_APP_BASE_URL+'logo.php',{
            method:'POST',
            body:formdata2
        }).then(res=>res.json()).then(res=>{
            if(res.path != ''){
                setLogo(res.path)
            }else{
                setLogo('')
            }
        })
        const formdata = new FormData()
        formdata.append('action', 'selectProject')
        fetch(process.env.REACT_APP_BASE_URL + 'selectFrontdata.php', {
            method: 'POST',
            body: formdata
            }).then(res => res.json()).then(res => {
            setData(res)
            })
    }
    , [])


    useMemo(async() => {
        if(code == 'tr'){
            setHome('Anasayfa')
            setAbout('Hakkımızda')
            setProjects('Projelerimiz')
            setContact('İletişim')
        }else{

            const H = await Translate(home, 'tr', localStorage.getItem('language'))
            const A = await Translate(about, 'tr', localStorage.getItem('language'))
            const P = await Translate(projects, 'tr', localStorage.getItem('language'))
            const C = await Translate(contact, 'tr', localStorage.getItem('language'))

            setHome(H.data.translations[0].translatedText)
            setAbout(A.data.translations[0].translatedText)
            setProjects(P.data.translations[0].translatedText)
            setContact(C.data.translations[0].translatedText)
            

        }
    }, [code])


  return (
    <>
    <div  className={!back?'hidden md:flex w-full navbar h-20  absolute z-20 top-0 left-0 items-center justify-between px-32 bg-black/10 border-b border-white':
    'hidden md:flex w-full navbar h-20  absolute z-20 top-0 left-0 items-center justify-between px-32 bg-black/50 border-b border-white'
    }>
        <span>
           {
                logo != '' ? <img src={process.env.REACT_APP_BASE_URL+'uploads/logo/'+logo} loading='lazy' rel='preload' alt={logo} width={'200px'} height={'100px'} className='h-16'/> : null
           }
        </span>    
        <span className='h-full flex items-center justify-center relative'>
            <ul className='flex items-center justify-center space-x-4 h-full'>
                <Link to='/' className='text-white whitespace-nowrap h-full capitalize flex items-center justify-center hover:text-blue-600 '>{home}</Link >
                <Link to='/about' className='text-white whitespace-nowrap capitalize h-full flex items-center justify-center hover:text-blue-600 '>{about}</Link >
                <Link  as='div' className='text-white group h-full flex items-center justify-center '>
                  <Link to='/projects' className='flex  whitespace-nowrap capitalize items-center justify-center hover:text-blue-600 '>
                  {projects}
                  <AiOutlineDown size={12} className='text-white group-hover:text-blue-600 group-hover:rotate-180 transition-all duration-700 text-xl ml-2'/>
                  </Link>
                  <ul className={`absolute group-hover:flex transition-all duration-700 hidden flex-col items-center justify-center left-40 top-[79px]`} >
                  {
                      data && data.map((item)=>{
                        return (
                            <Link key={'/projects/'+item.id} to={'/projects/'+item.id} className='text-white bg-black/50 min-w-full whitespace-nowrap px-8 p-4'>{item.name}</Link>
                        )
                    })
                  }
                  
            </ul>
           
                </Link >
                  
                <Link to='/contact' className='text-white flex h-full items-center justify-center hover:text-blue-600 '>{contact}</Link>
            </ul>
            <Dil ></Dil>
                  
        </span>
        
       
    </div>
    <GiHamburgerMenu  size={32} className='md:hidden fixed top-4 right-4 z-10 text-black' onClick={()=>mobile.current.classList.toggle('hidden')}/>
    <motion.div
    as='div'
    ref={mobile}
    initial={{opacity:0}}
    animate={{opacity:1}}
    className='hidden md:hidden w-full h-screen bg-black/50 fixed top-0 left-0 z-50 flex items-center justify-center'
      >
        <div className='w-full h-full bg-white flex flex-col items-start px-12 justify-center space-y-12'>
            <RxCross1 size={32} className='absolute top-4 right-4 text-black hover:text-blue-600' onClick={()=>mobile.current.classList.toggle('hidden')}/>
            <Link to='/' className='text-black text-2xl hover:text-blue-800 flex items-center justify-center gap-2'>
                <BiHome></BiHome>
                {home}</Link >
            <Link to='/about' className='text-black text-2xl hover:text-blue-800 flex items-center justify-center gap-2'>
                <AiOutlineInfoCircle></AiOutlineInfoCircle>
                {about}</Link >
            <Link to='/projects' className='text-black text-2xl hover:text-blue-800 flex items-center justify-center gap-2'>
                <MdOutlineHomeWork></MdOutlineHomeWork>
                {projects}</Link >
            <Link to='/contact' className='text-black text-2xl hover:text-blue-800 flex items-center justify-center gap-2'>
                <BsTelephoneFill></BsTelephoneFill>
                {contact}</Link >
            <div className=' h-32 flex items-start justify-start'>
                <Dil></Dil>

            </div>
        </div>
    </motion.div>

                
       
    
    </>
  )
}

export default Navbar