import React, { useEffect, useMemo } from 'react'
import Text from '../../../Components/admin/Text'
import Acor from '../../../Components/admin/Acor';
import { useState } from 'react';
import { Editor } from 'primereact/editor';
import Fileİnput from '../../../Components/admin/Fileİnput';
import toast, { Toaster } from 'react-hot-toast';


const Add = () => {

  const [name, setName] = useState('')
  const [title, setTitle] = useState('')
  const [subtitle, setSubtitle] = useState('')
  const [description, setDescription] = useState('')
  const [content, setContent] = useState('')
  const [bottomContent, setBottomContent] = useState('')  
  const [video, setVideo] = useState('')
  const [slayt, setSlayt] = useState([])
  const [banner, setBanner] = useState([])
  const [kart, setKart] = useState([])
  const [galeri, setGaleri] = useState([])
  const [back, setBack] = useState([])
 


  
  

  const sumbitHandel = () => {
    const formdata = new FormData();
    formdata.append('proje',name)
    formdata.append('baslik',title)
    formdata.append('altbaslik',subtitle)
    formdata.append('aciklama',description)
    formdata.append('icerik',content)
    formdata.append('sonicerik',bottomContent)
    formdata.append('video',video)
    for(let i=0;i<slayt.length;i++){
      formdata.append('slayt[]',slayt[i])
    }
    for(let i=0;i<banner.length;i++){
      formdata.append('banner[]',banner[i])
    }
    for(let i=0;i<kart.length;i++){
      formdata.append('kart[]',kart[i])
    }
    for(let i=0;i<galeri.length;i++){
      formdata.append('galeri[]',galeri[i])
    }
    for(let i=0;i<back.length;i++){
      formdata.append('arkaplan[]',back[i])
    }
    

    fetch(process.env.REACT_APP_BASE_URL+'project.php', {
      method: 'POST',
      body: formdata
    }).then(res=>res.json()).then(res=>{
      if(res.status != 200){
        toast.error(res.message)
      }else{
        toast.success(res.message)
      }
    })
  }

  useEffect(() => {
    document.title = 'Proje Ekle'
  }, [])
  


  


  
  return (
  <div className='w-full flex items-start  gap-2 justify-start min-h-screen'>
    <Toaster position='top-left' />
    <div className='w-full h-screen flex-col flex items-start justify-start px-6 py-4 bg-white border-r overflow-y-scroll border-black'>
      <p className='w-full text-left text-2xl p-1 py-4 border-b border-black mb-2 '>Yazı Alanları</p>
   
   <Acor title='Yazı Alanları'  >
   <Acor title={'Tekli yazılar'}>
       <Text title={'Proje Adı'}  func={setName} value={name}></Text>
        <Text title={'Başlık'}    func={setTitle} value={title}></Text>
        <Text title={'Alt başlık'}func={setSubtitle} value={subtitle}></Text>
        
       </Acor>
       <Acor title={'İçerik bilgileri'}>
      
        <Acor title={'Açıklama'}>
          <Editor className='min-h-full' onTextChange={(e)=>{setDescription(e.htmlValue)}} value={description}></Editor>
        </Acor>
        <Acor title={'Orta içerik'}>
          <Editor className='min-h-full' onTextChange={(e)=>{setContent(e.htmlValue)}} value={content} ></Editor>
        </Acor>
        <Acor title={'Son içerik'}>
          <Editor className='min-h-full' onTextChange={(e)=>{setBottomContent(e.htmlValue)}} value={bottomContent}></Editor>
        </Acor>
        <Text title={'Video linki'}    func={setVideo} value={video}></Text>
        
       </Acor>
      
    </Acor>
    <p className='w-full text-left text-2xl p-1 py-4 border-b border-black  mb-2'>Resim Alanları</p>
    <Acor title={'Resimler'} >
      <Acor title={'Slayt Resimleri'}>
         <Fileİnput func={setSlayt} value={slayt} name='slayt' title='Slayt Resimleri' />
      </Acor>
      <Acor title={'Banner Resimi'}>
         <Fileİnput func={setBanner} value={banner} name='banner' title='Slayt Resimleri' />
      </Acor>
      <Acor title={'Kart Resimleri'}>
         <Fileİnput func={setKart} value={kart} name='kart' title='Slayt Resimleri' />
      </Acor>
      <Acor title={'Galeri Resimleri'}>
         <Fileİnput func={setGaleri} value={galeri} name='galeri' title='Slayt Resimleri' />
      </Acor>
      <Acor title={'Arkaplan Resim'}>
         <Fileİnput func={setBack} value={back} name='back' title='Slayt Resimleri' />
      </Acor>
     
      
    </Acor>
       
    <div className='w-full flex items-center justify-end'>
       <button onClick={()=>{sumbitHandel()}} className='w-full lg:w-32 hover:bg-indigo-500  bg-indigo-600 text-white p-2 rounded'>Kaydet</button>
    </div>
    </div>
    
  </div>
  )
}

export default Add