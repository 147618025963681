import React, { useEffect, useMemo } from 'react'
import { BsMap,BsPhone } from 'react-icons/bs'
import {CiMail} from 'react-icons/ci'
import Translate from '../../Helper/Translate'
import { useSelector } from 'react-redux'
import { useState } from 'react'

const ContactCard = ({title,subTitle, phone, adress, mail}) => {
    const code = useSelector(state => state.Language.language)
   
    const [phoneTitle,setPhoneTitle] = useState('Bizi Arayın')
    const [mailTitle,setMailTitle] = useState('Mail Adresimiz')
  
    
    

    useMemo(async () => {
        if(code=='tr'){
            setPhoneTitle('Bizi Arayın')
            setMailTitle('Mail Adresimiz')
        }else{
            
            const pt = await Translate('Bizi Arayın','tr',code)
            const mt = await Translate('Mail Adresimiz','tr',code)           
            
            setPhoneTitle(pt.data.translations[0].translatedText)
            setMailTitle(mt.data.translations[0].translatedText)
            
            
        }
    }, [code])
    
    

  return (
    <div className='w-full min-h-screen flex flex-col'>
        <div className='w-full h-[50vh] flex items-center justify-center flex-col gap-6 px-4'>
            <h1 className='text-5xl text-black tracking-widest uppercase text-center font-light'>
              {title}
            </h1>
            <h1 className='text-3xl text-slate-600 tracking-widest text-center font-light'>
              {subTitle}
            </h1>
        </div>
        <div className='w-full flex-1 grid md:grid-cols-2 place-items-center gap-2 p-12'>
            
            <div className='w-full h-full bg-white rounded flex-col lg:flex-row flex items-center justify-center gap-3 p-8 lg:p-0'>
                <div className='w-32 px-2 flex items-center justify-center'>
                <BsPhone size={48} className='text-3xl text-blue-600'/>
                </div>
                <div className='flex-1 flex flex-col lg:items-start h-full justify-center gap-4'>
                    <h1 className='font-normal text-2xl text-center lg:text-left ml-2 w-full text-slate-900 capitalize'>
                        {phoneTitle}
                    </h1>
                    <a href={`tel:${phone}`} className='text-lg text-slate-600 font-light'>
                        {phone}
                    </a>

                </div>

            </div>
            <div className='w-full h-full bg-white rounded flex-col lg:flex-row flex items-center justify-center gap-3 p-8 lg:p-0'>
                <div className='w-32 px-2 flex items-center justify-center'>
                <CiMail size={48} className='text-3xl text-blue-600'/>
                </div>
                <div className='flex-1 flex flex-col items-start h-full justify-center gap-4'>
                    <h1 className='font-normal text-2xl text-center lg:text-left w-full text-slate-900 capitalize'>
                      {mailTitle}
                    </h1>
                    <a href={`mailto:${mail}`} className='text-lg text-slate-600 font-light'>
                        {mail}
                    </a>

                </div>

            </div>

        </div>
    </div>
  )
}

export default ContactCard