import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import 'leaflet/dist/leaflet.css';
import { PrimeReactProvider, PrimeReactContext } from 'primereact/api';
import "primereact/resources/themes/lara-light-indigo/theme.css";     
import "primereact/resources/primereact.min.css";
import {store} from './Redux/store'
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux'


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
<Provider store={store}>
   <BrowserRouter>
    <PrimeReactProvider>
    <App />
    </PrimeReactProvider>
  </BrowserRouter>
  </Provider> 
);
