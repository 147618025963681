import React from 'react'
import Gallery from '../../../Components/admin/Gallery'
import Text from '../../../Components/admin/Text'
import Acor from '../../../Components/admin/Acor';
import MobileHeader from '../../../Components/admin/MobileHeader';
import {AiFillPhone} from 'react-icons/ai'
import { useState,useEffect } from 'react';
import toast, { Toaster } from 'react-hot-toast';

const AdminContact = () => {
  
  const [header,setHeader] = useState('')
  const [text,setText] = useState('')
  const [adress,setAdress] = useState('')
  const [phone,setPhone] = useState('')
  const [mail,setMail] = useState('')
  const [instagram,setInstagram] = useState('')
  const [facebook,setFacebook] = useState('')
  const [twitter,setTwitter] = useState('')
  const [location,setLocation] = useState('')


  const dataFetch = async () => {
    const formdata = new FormData();
    formdata.append('action','selectContact')
    fetch(process.env.REACT_APP_BASE_URL + 'contact.php',{
      method:'POST',
      body:formdata
    }).then(res=>res.json()).then(res=>{
      console.log(res)
      setHeader(res.header)
      setText(res.text)
      setAdress(res.adress)
      setPhone(res.phone)
      setMail(res.mail)
      setInstagram(res.instagram)
      setFacebook(res.facebook)
      setTwitter(res.twitter)
      setLocation(res.location)
      
    })

  }

  useEffect(() => {
    document.title = 'İletişim'
    dataFetch()
    return () => {
      console.log('cleaned up')
    }
    
  }
  , [])

  const updateHandel = () => {
    const formdata = new FormData();
    formdata.append('action','update')
    formdata.append('tableName','contact')
    formdata.append('header',header)
    formdata.append('text',text)
    formdata.append('adress',adress)
    formdata.append('phone',phone)
    formdata.append('mail',mail)
    formdata.append('instagram',instagram)
    formdata.append('facebook',facebook)
    formdata.append('twitter',twitter)
    formdata.append('location',location)
    fetch(process.env.REACT_APP_BASE_URL + 'contact.php',{
      method:'POST',
      body:formdata
    }).then(res=>res.json()).then(res=>{
      if(res.status != 200){
        toast.error('Bir hata oluştu')
      }else{
        toast.success('Kaydedildi')
      }
    })

  }
  


  return (
    <div className='w-screen h-screen overflow-scroll'>
    <Toaster position='top-right' />
      <MobileHeader title={'İletişim'} icon={<AiFillPhone size={32} className='text-white'></AiFillPhone>}></MobileHeader>
    <div className='w-full h-auto flex flex-col items-center justify-center p-2 gap-4 '>
    <Text title={'Sayfa başlığı'}></Text>
    <Gallery title={'Arka Plan'} name='bg' url={process.env.REACT_APP_BASE_URL + 'contact.php'} path={'contact/background'} tableName='contact' columnName='background'></Gallery>

     <Acor  title={'Yazı alanları'} >
     
          
           <Acor title={'Slogan alanı'}>
               <Text func={setHeader} value={header} title={'Slogan başlık'}></Text>
               <Text func={setText} value={text} title={'Slogan alt başlık'}></Text>
               
               
           </Acor>
           <Acor title={'İletişim bilgileri'}>
              <Text func={setAdress} value={adress} title={'Adres'}></Text>
              <Text func={setPhone} value={phone} title={'Telefon numarası'}></Text>
              <Text func={setMail} value={mail} title={'Mail adresi'}></Text>
              <Text func={setInstagram} value={instagram} title={'İnstagram'}></Text>
              <Text func={setFacebook} value={facebook} title={'Facebook'}></Text>
              <Text func={setTwitter} value={twitter} title={'Twitter'}></Text>
           </Acor>
            <Acor title={'Konum bilgisi'}>
              <Text func={setLocation} value={location} title={'Konum linki'}></Text>
              
            </Acor>
        
       </Acor>
       <div className='w-full flex items-center justify-end py-2'>
            <button onClick={() => { updateHandel() }} className='bg-blue-600 p-4 lg:w-32 rounded-md w-full  text-white'>Kaydet</button>
       </div>
    
   </div>
   
 </div>
  )
}

export default AdminContact