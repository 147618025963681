const Map = ({location}) => {
  return (
   <div className='w-full h-screen lg:p-12' >
    <iframe src={location} width="100%" height="100%" style={{border:0}} allowFullScreen="" loading="lazy"></iframe>

    
   </div>
  )

}

export default Map