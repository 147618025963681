import React, { useEffect, useMemo } from 'react'
import Navbar from '../Components/Navbar'
import Main from '../Components/Swiper/Main'
import AboutCard from '../Components/Cards/AboutCard'
import House from '../Components/Swiper/House'
import Footer from '../Components/Footer'
import 'moment/locale/tr'
import Translate from '../Helper/Translate'
import { useSelector } from 'react-redux'
import { Suspense } from 'react'
const Home = () => {

 
  const [bg, setBg] = React.useState('')
  const [link ,setLink]= React.useState('')
  const [card, setCard] = React.useState('')
  const [data, setData] = React.useState([])
  const [sideTitle, setSideTitle] = React.useState('')
  const [sideHeader, setSideHeader] = React.useState('')
  const [sideText, setSideText] = React.useState('')
  const [sideTitleTr, setSideTitleTr] = React.useState('')
  const [sideHeaderTr, setSideHeaderTr] = React.useState('')
  const [sideTextTr, setSideTextTr] = React.useState('')
  const [title, setTitle] = React.useState('')
  const [titleTr, setTitleTr] = React.useState('')
  const [send ,setSend]= React.useState('')
  const [bottomSlayt, setBottomSlayt] = React.useState('')
  const code = useSelector(state => state.Language.language)



 
  const dataFetch = async () => {
    fetch(process.env.REACT_APP_BASE_URL + 'folderManager.php')
      const formdata = new FormData()
    formdata.append('action', 'home')
   const res = await fetch(process.env.REACT_APP_BASE_URL + 'selectFrontdata.php', {
      method: 'POST',
      body: formdata
    })
    const data = await res.json()
   
      setData(data)
      setSideHeaderTr(data.sideTitle)
      setSideTextTr(data.sideText)
      setTitleTr(data.bottomSliderTitle)
      setTitle(data.bottomSliderTitle)
      setSideTitleTr(data.bannerTitle)
      setSideTitle(data.bannerTitle)
      setSideHeader(data.sideTitle)
      setSideText(data.sideText)
      setBg(data.sliderBackground)
      setCard(data.cardBackground)
      setLink(data.link)
      setBottomSlayt(data.bottomSlayt.split(','))
      document.scroll = 0
  }
  
  const getLocation = async () => {
    if(navigator.geolocation){
      navigator.geolocation.getCurrentPosition((position)=>{
        const latitude = position.coords.latitude
        const longitude = position.coords.longitude
        
        fetch(`https://api.bigdatacloud.net/data/reverse-geocode-client?latitude=${latitude}&longitude=${longitude}&localityLanguage=tr`)
        .then(res=>res.json())
        .then(res=>{
          console.log(res)
          const formdata = new FormData()
          formdata.append('action','location')
          formdata.append('ilçe',res.city)
          formdata.append('il',res.principalSubdivision)
          formdata.append('ülke',res.countryName)
          formdata.append('kıta',res.continent)
          
          fetch(process.env.REACT_APP_BASE_URL + 'location.php',{
            method:'POST',
            body:formdata
          }).then(res=>res.json()).then(res=>{console.log(res)


          })
        })
      })
    
    }else{
      return false
    }
  }


  useEffect(() => {
    document.title = 'Anasayfa'    
    getLocation()
    
    dataFetch()
    return () => {
      console.log('cleaned up')
    }
  }, [])


  useMemo(async () => {
    

    if (code == 'tr') {
      setSideTitle(sideTitleTr)
      setSideHeader(sideHeaderTr)
      setSideText(sideTextTr)
      setTitle(titleTr)
      setSend('Keşfet')
      document.title = 'Anasayfa'

      return () => {
        return false
      }

    }else {
      const pageTitle = await Translate('Anasayfa', 'tr', localStorage.getItem('language'))
      document.title = pageTitle.data.translations[0].translatedText

      const sideTi = await Translate(data.bannerTitle, 'tr', localStorage.getItem('language'))
      const sideHe = await Translate(data.sideTitle, 'tr', localStorage.getItem('language'))
      const sideTe = await Translate(data.sideText, 'tr', localStorage.getItem('language'))
      const title = await Translate(data.bottomSliderTitle, 'tr', localStorage.getItem('language'))
      const sd = await Translate('Keşfet', 'tr', localStorage.getItem('language'))

      setSend(sd.data.translations[0].translatedText)
      sideHe && setSideHeader(sideHe.data.translations[0].translatedText)
      sideTe && setSideText(sideTe.data.translations[0].translatedText)
      title && setTitle(title.data.translations[0].translatedText)
      sideTi && setSideTitle(sideTi.data.translations[0].translatedText)




    }

    return () => {
      console.log('cleaned up')
    }



  }, [code])



  return (
  <div className='w-full min-h-screen flex flex-col relative  items-center bg-slate-50'>
    <Navbar back={true}></Navbar>
    <Main bg={bg}></Main>
    <AboutCard send={send} card={card} sideTitle={sideTitle} sideHeader={sideHeader} sideText={sideText} reverse={true} folder={'home'} data={data} btn={true} />
    <div className='w-full h-[100vh] bg-slate-100 '>
    <iframe width="100%"
     height="100%"
     src={link}
     title="YouTube video player"
     loading='lazy'
     frameBorder="0" 
     allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
     allowFullScreen>

    </iframe>
    </div>
    <House data={bottomSlayt} title={title} />
    <Footer />

  </div>
  )
}

export default Home