import React, { useEffect } from 'react'
import Mailbox from '../../../Components/admin/Mailbox';
import toast ,{Toast}from 'react-hot-toast';


const Mail = () => {

  const [data,setData] = React.useState([])
  useEffect(() => {
    document.title = 'Mail'
    const formdata = new FormData();
    formdata.append('action','mailGet')
    fetch(process.env.REACT_APP_BASE_URL+'mail.php', {
      method: 'POST',
      body: formdata
    }).then(res=>res.json()).then(res=>{
     setData(res)
    })
      

    return () => {
      console.log('cleaned up')
    }
  }, [])

  
  
  return (
    <div className='w-screen h-screen overflow-scroll flex items-start justify-start p-6 flex-col gap-16'>
    
    {
     
     data && data.map((item,index)=>{
        return(
          <Mailbox key={index} data={item} />
        )
      })
      
    }
   
   
 </div>
  )
}

export default Mail