import React from 'react'
import { Accordion,AccordionTab } from 'primereact/accordion'
const Acor = ({children,title}) => {
  return (
    <div className='w-full'>
        <Accordion activeIndex={null}   className=''>
            <AccordionTab header={title} className='p-0'>
                {
                    children
                }
            </AccordionTab>
        </Accordion>
    </div>
    
  )
}

export default Acor