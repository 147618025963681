import React, { useEffect } from 'react'
import { useState } from 'react';
import { PanelMenu } from 'primereact/panelmenu';
import {IoExitOutline} from 'react-icons/io5'
import {AiOutlineHome,AiOutlinePlus,AiOutlineMail,AiOutlineLineChart,AiOutlineInfoCircle,AiFillPhone,AiOutlinePicture} from 'react-icons/ai'
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { useSelector , useDispatch } from 'react-redux';    
import { logout } from '../../Reducers/Auth';

const AdminNav = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const user = useSelector(state => state.Auth.user)
    const [ logo , setLogo ] = useState('')

   useEffect(() =>{
    const formdata = new FormData()
    formdata.append('action','select')

    fetch(process.env.REACT_APP_BASE_URL+'logo.php',{
        method:'POST',
        body:formdata
    }).then(res=>res.json()).then(res=>{
        if(res.path != ''){
            setLogo(res.path)
        }else{
            setLogo('')
        }
    })

    
         if(!user){
              navigate('/admin')
         }

   },[user])

    const items = [
        {
            label:'Sayfa Yönetimi',
            
            items:[
                {
                    label:'Anasayfa',
                    icon: <AiOutlineHome className='mr-2'/>,
                    url:'/dashboard'
                },
                {
                    label:'Hakkımda',
                    icon: <AiOutlineInfoCircle className='mr-2'/>,
                    url:'/dashboard/about'
                    
                },
                
                {
                    label:'İletişim',
                    icon: <AiFillPhone className='mr-2'/>,
                    url:'/dashboard/contact',
                },
                {
                    label:'Projeler',
                    icon: <AiOutlinePlus className='mr-2'/>,
                    url:'/dashboard/add',

                }
            ]
        }
    ]

    const logoutHandel = () => {
        dispatch(logout())
        window.localStorage.removeItem('userToken')
        console.log(user)
    }

  return (
    <div className='relative hidden w-52 bg-slate-600 lg:flex flex-col min-h-screen items-center  justify-start p-4'>
        {
            logo != '' ? <img src={process.env.REACT_APP_BASE_URL+'uploads/logo/'+logo} alt={`Image-`} className="w-full h-16 aspect-square object-cover " /> : <div className='default w-full h-16 rounded-md'></div>
        }
        <span className='w-full border bg-slate-400 mt-4'></span>
        <div className='flex flex-col flex-1 items-start justify-start gap-2 py-4'>
        <PanelMenu model={items} className='whitespace-nowrap ' />
        <Link to='/dashboard/statistics' className='w-full group bg-slate-50 p-4  text-slate-500 font-semibold rounded-md flex items-center gap-2 justify-start hover:bg-slate-200'>
           <AiOutlineLineChart className='group-hover:text-black'></AiOutlineLineChart>
           <span className='group-hover:text-black' >İstatistikler</span>
        </Link>
        
        <Link to='/dashboard/mail' className='w-full group bg-slate-50 p-4  text-slate-500 font-semibold rounded-md flex items-center gap-2 justify-start hover:bg-slate-200'>
           <AiOutlineMail className='group-hover:text-black'></AiOutlineMail>
           <span className='group-hover:text-black' >Mailler</span>
        </Link>
        <Link to='/dashboard/logo' className='w-full group bg-slate-50 p-4  text-slate-500 font-semibold rounded-md flex items-center gap-2 justify-start hover:bg-slate-200'>
           <AiOutlinePicture className='group-hover:text-black'></AiOutlinePicture>
           <span className='group-hover:text-black' >Logo Ayarı</span>
        </Link>
        <button onClick={()=>{logoutHandel()}} className='absolute bottom-4 left-2  w-11/12 group bg-red-500 p-4  text-slate-500 font-semibold rounded-md flex items-center gap-2 justify-start hover:bg-red-600'>
            <IoExitOutline className='w-6 h-6 text-white'></IoExitOutline>
            <p  className='text-white'>Çıkış yap</p>
        </button>
        </div>   
    </div>
  )
}

export default AdminNav