import React from 'react'
import { useState } from 'react';
import toast, { Toaster } from 'react-hot-toast';
import { useDispatch , useSelector } from 'react-redux';
import { login } from '../../Reducers/Auth';
import { useNavigate } from 'react-router-dom';



const Login = () => {

  const navigate = useNavigate()
  const dispatch = useDispatch()
  const user = useSelector(state => state.Auth.user)
  const [name, setName] = useState('')
  const [password, setPassword] = useState('')
  const LoginHandel = (e) => {
  
    const formdata = new FormData()
    formdata.append ('action','login')
    formdata.append ('name',name) 
    formdata.append ('password',password)
    fetch(process.env.REACT_APP_BASE_URL+'auth.php',{
      method:'POST',
      body:formdata
  
    })
    .then(res=>res.json())
    .then(data=>{
      if(data.status != 200){
        toast.error(data.message)
      }else{
        toast.success(data.message)
        window.localStorage.setItem('userToken',data.userToken)
        dispatch(login(data.userToken))
        navigate('/dashboard?userToken='+data.userToken)
        
      }
    })
    
  
  
  
  
  }

  return (
    <div className='flex flex-col items-center justify-center w-full h-screen bg-slate-50'>
      <Toaster position='bottom-center'></Toaster>
        <div className='flex items-center justify-center  flex-col  gap-4'>
            <h1 className='text-2xl tracking-[4px]'>
              Yönetim Paneli
            </h1>
            <div className='flex flex-col'>
                <input value={name} onChange={(e)=>{setName(e.target.value)}} type='text' placeholder='Username' className='w-56 h-10 px-2 py-1 my-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-gray-300'/>
                <input value={password} onChange={(e)=>{setPassword(e.target.value)}} type='password' placeholder='Password' className='w-56 h-10 px-2 py-1 my-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-gray-300'/>
                <button onClick={(e)=>{LoginHandel(e)}} className='w-56 h-10 my-2 text-white bg-gray-500 rounded-md focus:outline-none focus:ring-2 focus:ring-gray-300'>Giriş Yap</button>
                
            </div>
        </div>
    </div>
  )
}

export default Login