import React, { useEffect } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';


const AboutSwiper = ({ title, background }) => {
    const [bg, setBg] = React.useState([])
    useEffect(() => {
        setBg(background.split(','))
        return () => {
            console.log('cleaned up')
        }


    }, [background])
    return (
        <div className='w-full min-h-screen flex flex-col items-center justify-center gap-12 px-14 py-32'>
            <h1 className='lg:text-6xl md:text-4xl text-2xl font-semibold md:font-light tracking-widest w-full text-center pt-12 border-b-2 py-4 border-black  text-black'>
                {title}
            </h1>
            <div className='w-full h-auto  flex items-center justify-center border-black  py-4 mx-auto'>
                <Swiper
                    className='w-full  !overflow-hidden flex-1 !items-center !flex !justify-between '
                    spaceBetween={50}
                    slidesPerView={4}
                    modules={[Autoplay]}
                    breakpoints={
                        {
                            0: {
                                slidesPerView: 1,
                                spaceBetween: 0
                            },
                            640: {
                                slidesPerView: 2,
                                spaceBetween: 100,
                            },
                            768: {
                                slidesPerView: 3,
                                spaceBetween: 75
                            },
                            1024: {
                                slidesPerView: 4,
                                spaceBetween: 50
                            }
                        }
                    }

                    autoplay={{ delay: 1222500, disableOnInteraction: false }}
                >
                    {
                        bg.map((item, index) => {
                            return (
                                <SwiperSlide key={index}>
                                    <div className='w-80 aspect-square bg-center bg-cover bg-no-repeat '
                                    style={{backgroundImage:`url(${process.env.REACT_APP_BASE_URL+'uploads/about/slider/'+item})`}}
                                    >
      {}
                                    </div>
                                </SwiperSlide>
                            )
                        })
                    }
                </Swiper>
            </div>
        </div>
    )
}

export default AboutSwiper