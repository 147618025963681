import React, { useEffect,useState } from 'react'
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import Acor from '../../../Components/admin/Acor';
import MobileHeader from '../../../Components/admin/MobileHeader';
import { TbReportAnalytics } from "react-icons/tb";
import {AiOutlineEye} from 'react-icons/ai'

const İstatislik = () => {


    const [data, setData] = useState([])
    const [project, setProject] = useState([])
    const dataFetch = () => {
        const formdata = new FormData()
        formdata.append('action', 'select')
        fetch(process.env.REACT_APP_BASE_URL + 'location.php', {
            method: 'POST',
            body: formdata
        }).then(res => res.json()).then(res => {
            setData(res)
        })
    }
    const selectProject = ()=>{
        const formdata = new FormData()
        formdata.append('action', 'selectTopProject')
        fetch(process.env.REACT_APP_BASE_URL + 'location.php', {
            method: 'POST',
            body: formdata
        }).then(res => res.json()).then(res => {
            setProject(res)
        })

    }

    useEffect(() => {
        document.title = 'İstatislik'
        dataFetch()
        selectProject()
        return () => {
        }
    }, [])
  
   

  return (
    <div className='w-screen h-screen overflow-scroll'>
              <MobileHeader title={'İstatislik'} icon={<TbReportAnalytics size={32} className='text-white'></TbReportAnalytics>}></MobileHeader>

       <div className='w-full h-auto flex flex-col items-center justify-center p-2 gap-4 '>
      
        <Acor title={'Son ziyaret edinme bilgileri'} >
         
            <DataTable value={data}>
                <Column field="ilce" header="ilçe" ></Column>
                <Column field="il" header="il"></Column>
                <Column field="ulke" header="ülke" ></Column>
                <Column field="kıta" header="Kıta" ></Column>
                <Column field="zaman" header="Tarih" ></Column>
            </DataTable>
        </Acor>
        <Acor title={'En çok incelenenler'} >
            <div className='w-full lg:grid-cols-4 md:grid-cols-2 grid-cols-1 grid gap-6 place-items-center h-auto'>
                {
                    project.map((item,index)=>{
                        return(
                           <div className='w-full h-auto flex flex-col items-center bg-slate-100 p-2 justify-center'>
                            <div 
                            style={{backgroundImage:`url(${process.env.REACT_APP_BASE_URL+'uploads/projects/'+item.name+'/slayt/'+item.slayt.split(',')[0]})`}}
                            className='w-full aspect-square bg-center bg-cover'></div>
                            <span className='w-full flex items-center justify-between'>
                            <span>
                            <h1 className='w-full p-2 flex items-center justify-start gap-4'>
                            <AiOutlineEye size={22}></AiOutlineEye>
                            {item.visit}
                            </h1>
                            </span>
                            <h1>{item.name}</h1>
                            </span>
                           </div>
                        )
                    }
                    )
                }
            </div>
        </Acor>
          
       
      </div>
      
    </div>
  )
}

export default İstatislik