import React, { useEffect } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Autoplay, Pagination, Navigation } from 'swiper/modules';


const Main = ({bg}) => {
    const [background ,setBackground ] = React.useState([])
    useEffect(() => {
       bg && setBackground(bg.split(','))
       return () => {
              console.log('cleaned up')
         }
    }, [bg])
  return  (
    
        <Swiper
            className='w-full p-0 h-screen'
            spaceBetween={0}
            slidesPerView={1}
            modules={[ Pagination, Autoplay]}
            pagination={{ clickable: true }}
          
            autoplay={{ delay: 1222500, disableOnInteraction: false }}
            >
           
             {
                 background.map((item,index)=>(
                    <SwiperSlide key={index}>
                    <div className='w-full h-screen flex items-center justify-center bg-black/10 bg-center bg-cover scale-100 transition-all  bg-no-repeat'
                    style={{backgroundImage :`url(${process.env.REACT_APP_BASE_URL+'uploads/home/background/'+item})`}}
                    >
                       
                    </div>
                </SwiperSlide>
                ))
             }
           

            </Swiper>
   
  )
}

export default Main