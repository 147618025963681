import { createSlice } from "@reduxjs/toolkit";

const initailState = {
    language: window.localStorage.getItem("language")  ? window.localStorage.getItem("language") : navigator.language.split('-')[0],
    };

const languageSlice = createSlice({
    name: "language",
    initialState: initailState,
    reducers: {
      chancheLanguage(state, action) {
            state.language = action.payload;
            window.localStorage.setItem("language", action.payload);
        },
    },
});

export const { chancheLanguage } = languageSlice.actions;
export default languageSlice.reducer;