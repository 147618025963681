import About from "./Pages/About";
import Contact from "./Pages/Contact";
import Home from "./Pages/Home";
import { Route ,Routes } from "react-router-dom";
import Projects from "./Pages/Projects";
import { ProjectDetail } from "./Pages/ProjectDetail";
import Login from "./Pages/admin/Login";
import Dashboard from "./Pages/admin/Dashboard";
import AdminHome from "./Pages/admin/pages/AdminHome";
import  AdminProjects  from "./Pages/admin/pages/AdminProjects";
import { AdminAbout } from "./Pages/admin/pages/AdminAbout";
import AdminContact from "./Pages/admin/pages/AdminContact";
import İstatislik from "./Pages/admin/pages/İstatislik";
import Add from "./Pages/admin/pages/Add";
import Mail from "./Pages/admin/pages/Mail";
import Edit from "./Pages/admin/pages/Edit";
import AdminLogo from "./Pages/admin/pages/AdminLogo";

function App() {
  return (
   <>
   <Routes>
      <Route path='/' element={<Home/>}/>
      <Route path='/about' element={<About/>}/>
      <Route path='/contact' element={<Contact/>}/>
      <Route path="/projects" element={<Projects/>}/>
      <Route path="/projects/:id" element={<ProjectDetail/>}/>
      <Route path='/admin' element={<Login></Login>} />
      

    </Routes>
    <Routes>
      <Route path='/dashboard' element={<Dashboard/>}>
        <Route path='/dashboard' element={<AdminHome/>}/>
        <Route path='/dashboard/about' element={<AdminAbout/>}/>
        <Route path='/dashboard/contact' element={<AdminContact/>}/>
        <Route path='/dashboard/statistics' element={<İstatislik/>}/>
        <Route path='/dashboard/add' element={<AdminProjects/>}/>
        <Route path='/dashboard/mail' element={<Mail/>}/>
        <Route path='/dashboard/logo' element={<AdminLogo/>}/>


        <Route path='/dashboard/addProject' element={<Add/>}/>
        <Route path='/dashboard/edit/:id' element={<Edit/>}/>
     
      </Route>
    </Routes>
   </>
  );
}

export default App;
