
const Translate = async (text = 'deneme', fromLang = 'tr', toLang = 'en') => {
  let url = `https://translation.googleapis.com/language/translate/v2?key=${process.env.REACT_APP_API_KEY}`;
  url += '&q=' + encodeURI(text);
  url += `&source=${fromLang}`;
  url += `&target=${toLang}`;

  try {
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json"
      }
    });

    if (response.ok) {
      const data = await response.json();
      return data; 
    } else {
      console.log("Çeviri isteği sırasında bir hata oluştu:", response.status);
      return null;
    }
  } catch (error) {
    console.log("Çeviri isteği sırasında bir hata oluştu:", error);
    return null;
  }
}

export default Translate;
