import React, { useMemo } from 'react'
import Navbar from '../Components/Navbar'
import AboutSwiper from '../Components/Swiper/AboutSwiper'
import AboutCard from '../Components/Cards/AboutCard';
import Footer from '../Components/Footer';
import HalfBanner from '../Components/HalfBanner';
import { useState,useEffect } from 'react';
import Translate from '../Helper/Translate'
import { useSelector } from 'react-redux'



const About = () => {

  
  
  const code = useSelector(state => state.Language.language)
  const [bg,setBg] = useState('')
  const [bgTitle,setBgTitle] = useState('')
  const [sideTitle,setSideTitle] = useState('')
  const [sideHeader,setSideHeader] = useState('')
  const [sideText,setSideText] = useState('')
  const [sideBackground,setSideBackground] = useState('')
  const [compName,setCompName] = useState('')
  const [lsideTitle,setLsideTitle] = useState('')
  const [lsideHeader,setLsideHeader] = useState('')
  const [lsideText,setLsideText] = useState('')
  const [lsideBackground,lsetSideBackground] = useState('')
  const [sliderTitle,setSliderTitle] = useState('')
  const [banner,setBanner] = useState('')
  const [sliderBackground,setSliderBackground] = useState('')
  const dataFetch =async() => {
    const formdata = new FormData()
    formdata.append('action','about')
    fetch(process.env.REACT_APP_BASE_URL+'selectFrontdata.php', {
      method: 'POST',
      body: formdata
    }).then(res => res.json())
    .then(async data => {
      document.scroll = 0
      setBg(data.background)
      setBgTitle(data.backgroundTitle)
      setSideTitle(data.sideTitle)
      setSideHeader(data.sideHeader)      
      setSideText(data.sideText)      
      setCompName(data.compName)
      setBanner(data.banner)      
      setSideBackground(data.sideBackground)
      setLsideTitle(data.lsideTitle)
      setLsideHeader(data.lsideHeader)
      setLsideText(data.lsideText)
      lsetSideBackground(data.lsideBackground)
      setSliderTitle(data.sliderTitle)
      setSliderBackground(data.aboutSlider)
     
    if(code =='tr'){
      setBg(data.background)
      setBgTitle(data.backgroundTitle)
      setSideTitle(data.sideTitle)
      setSideHeader(data.sideHeader)      
      setSideText(data.sideText)      
      setCompName(data.compName)
      setBanner(data.banner)      
      setSideBackground(data.sideBackground)
      setLsideTitle(data.lsideTitle)
      setLsideHeader(data.lsideHeader)
      setLsideText(data.lsideText)
      lsetSideBackground(data.lsideBackground)
      setSliderTitle(data.sliderTitle)
      setSliderBackground(data.aboutSlider)
      document.title = 'Hakkımızda'
    }else{
      setBg(data.background)
      const pageTitle = await Translate('Hakkımızda', 'tr', localStorage.getItem('language'))
      document.title = pageTitle.data.translations[0].translatedText
      const bgTi =  await Translate(data.backgroundTitle,'tr',code)
      const sideTi = await Translate(data.sideTitle,'tr',code)
      const sideHe = await Translate(data.sideHeader,'tr',code)
      const sideTe = await Translate(data.sideText,'tr',code)
      const lsideTi = await Translate(data.lsideTitle,'tr',code)
      const lsideHe = await Translate(data.lsideHeader,'tr',code)
      const lsideTe = await Translate(data.lsideText,'tr',code)
      const sliderTi = await Translate(data.sliderTitle,'tr',code)


      setBgTitle(bgTi.data.translations[0].translatedText)
      setSideTitle(sideTi.data.translations[0].translatedText)
      setSideHeader(sideHe.data.translations[0].translatedText)
      setSideText(sideTe.data.translations[0].translatedText)
      setLsideTitle(lsideTi.data.translations[0].translatedText)
      setLsideHeader(lsideHe.data.translations[0].translatedText)
      setLsideText(lsideTe.data.translations[0].translatedText)
      setSliderTitle(sliderTi.data.translations[0].translatedText)



    }
    
    })


   
  }
  useEffect(() => {
    dataFetch()
    return () => {
      console.log('cleaned up')
    }
  }, [code])

  
 
  
  return (
    <div className='w-full min-h-screen flex flex-col relative  items-center bg-slate-50'>
        <Navbar></Navbar>
        <HalfBanner text={bgTitle} img={process.env.REACT_APP_BASE_URL+'uploads/about/background/'+bg}/>
        <AboutCard btn={false} compName={compName} bannerTitle={sideTitle} sideTitle={sideTitle} r={false} sideText={sideText} sideHeader={sideHeader}  folder={'about'} reverse={true} card={sideBackground}/>
       <div className='w-full bg-center bg-cover h-[60vh] '
        style={{backgroundImage:`url(${process.env.REACT_APP_BASE_URL+'uploads/about/banner/'+banner})`}}
       />
       <AboutCard btn={false} reverse={'true'} compName={compName}  sideTitle={lsideTitle} sideText={lsideText} r={true} sideHeader={lsideHeader}  folder={'about'}  card={lsideBackground}/>

        <AboutSwiper background={sliderBackground} title={sliderTitle}></AboutSwiper>
        <Footer></Footer>
    </div>
  )
  
}

export default About