
const Text = ({title,value,func}) => {
  
  
    
 
  return (
    <div className='w-full flex  flex-col items-center justify-center rounded p-4 gap-4 mt-2'>
       
        <input onChange={e=>{func(e.target.value)}} value={value}  type="text"  placeholder={title} className='input placeholder:text-black w-full focus:placeholder:text-white flex-1 p-1 focus:outline-none focus:border-indigo-600 border-b-2 border-black '>
         
        </input>
        
    </div>
  )
}

export default Text