import React, { useEffect ,useState,useMemo} from 'react'
import Navbar from '../Components/Navbar'
import HalfBanner from '../Components/HalfBanner'
import ContactCard from '../Components/Cards/ContactCard'
import Map from '../Components/Map'
import ConcatcForm from '../Components/Forms/ContactForm'
import Footer from '../Components/Footer'
import { useSelector } from 'react-redux'
import Translate from '../Helper/Translate'




const Contact = () => {

  const code = useSelector(state => state.Language.language)
  const [header,setHeader] = useState('')
  const [headerTr,setHeaderTr] = useState('')
  const [text,setText] = useState('')
  const [textTr,setTextTr] = useState('')
  const [adress,setAdress] = useState('')
  const [phone,setPhone] = useState('')
  const [mail,setMail] = useState('')
  const [instagram,setInstagram] = useState('')
  const [facebook,setFacebook] = useState('')
  const [twitter,setTwitter] = useState('')
  const [location,setLocation] = useState('')
  const [background,setBackground] = useState('')

  const [name,setName] = useState('Adınız')
  const [mailAdress,setMailAdress] = useState('Mail Adresiniz')
  const [message,setMessage] = useState('Mesajınız')
  const [subject,setSubject] = useState('Konu')
  const [send,setSend] = useState('Gönder')
  const [Contact,setContact] = useState('Bize Ulaşın')



  const dataFetch = async () => {
    const formdata = new FormData();
    formdata.append('action','contact')
    fetch(process.env.REACT_APP_BASE_URL + 'selectFrontdata.php',{
      method:'POST',
      body:formdata
    }).then(res=>res.json())
    .then(async res=>{
      document.scroll = 0
        setHeader(res.header)
        setText(res.text)
        setAdress(res.adress)
        setPhone(res.phone)
        setMail(res.mail)
        setInstagram(res.instagram)
        setFacebook(res.facebook)
        setTwitter(res.twitter)
        setLocation(res.konum)
        setBackground(res.background)
        setName('Adınız')
        setMailAdress('Mail Adresiniz')
        setMessage('Mesajınız')
        setSubject('Konu')
        setSend('Gönder')
        setContact('Bize Ulaşın')
        document.title = 'İletişim'
      if(code != 'tr'){
        const pageTitle = await Translate('İletişim', 'tr', localStorage.getItem('language'))
        document.title = pageTitle.data.translations[0].translatedText
        const He = await Translate(res.header,'tr',code)
        const Te = await Translate(res.text,'tr',code)

        const nt = await Translate('Adınız','tr',code)
        const mat = await Translate('Mail Adresiniz','tr',code)
        const st = await Translate('Konu','tr',code)
        const sen = await Translate('Gönder','tr',code)
        const msg = await Translate('Mesajınız','tr',code)
        const ct = await Translate('Bize Ulaşın','tr',code)

        setHeader(He.data.translations[0].translatedText)
        setText(Te.data.translations[0].translatedText)
        setContact(ct.data.translations[0].translatedText)

        setName(nt.data.translations[0].translatedText)
        setMailAdress(mat.data.translations[0].translatedText)
        setMessage(msg.data.translations[0].translatedText)
        setSubject(st.data.translations[0].translatedText)
        setSend(sen.data.translations[0].translatedText)
      



      }else{
        setHeader(res.header)
        setText(res.text)
      }
        
     
    })
  }

  useEffect(() => {
    dataFetch()
    return () => {
      
    }
    
  }, [code])


  

 


  


  return (
    <div className='w-full min-h-screen flex flex-col relative  items-center bg-slate-50'>
        <Navbar></Navbar>
        <HalfBanner img={process.env.REACT_APP_BASE_URL+'uploads/contact/background/'+background}></HalfBanner>
        <ContactCard title={header} subTitle={text} phone={phone}  mail={mail}></ContactCard>
        <Map location={location}></Map>
        <ConcatcForm contact={Contact} name={name} mail={mailAdress} subject={subject} send={send} message={message} ></ConcatcForm>
        <Footer></Footer>
    </div>
  )
}

export default Contact