import React, { useEffect } from 'react'
import emailjs from 'emailjs-com';
import { useRef } from 'react';
import { useState } from 'react';
import toast, { Toaster } from 'react-hot-toast';



const ContactForm = ({ name, send, mail, message }) => {
    const form = useRef();
    const [ userName,setUserName ] = useState('');
    const [ userMail, setUserMail ] = useState('');
    const [ userMessage,setUserMessage ] = useState('');

    useEffect(() => {
        emailjs.init(process.env.REACT_APP_MAIL_KEY)
    }, [])

    const sendEmail =  (e) => {
        e.preventDefault();
        const formdata = new FormData();
        formdata.append('action','mailSend')
        formdata.append('name',userName)
        formdata.append('mail',userMail)
        formdata.append('message',userMessage)
       
        fetch(process.env.REACT_APP_BASE_URL+'mail.php', {
          method: 'POST',
          body: formdata
        }).then(res=>res.json()).then(res=>{
          if(res.status != 200){
            toast.error(res.message)
          }else{
            toast.success(res.message)
            emailjs.sendForm(process.env.REACT_APP_SERVICE, process.env.REACT_APP_TEMPLATE, form.current,process.env.REACT_APP_MAIL_KEY)
            .then((result) => {
                
                toast.success('Mailiniz başarıyla gönderildi.')
            }, (error) => {
                toast.error('Mailiniz gönderilemedi.')
            });
          }
        })

       
    };
    return (

        <form ref={form} className='w-full gap-4 lg:px-52 px-20 py-32  flex items-center justify-center  flex-col' onSubmit={sendEmail}>
        <Toaster position='top-left' />
        
       <span className='w-full flex flex-col lg:flex-row items-center justify-center gap-4'>
       <input type="text" value={userName} onChange={(e)=>{setUserName(e.target.value)}} className="w-full h-20 p-4 bg-slate-50 border-slate-400 border placeholder:text-black" placeholder={name} name="from_name" />
        
        
        <input type="email"  value={userMail} onChange={(e)=>{setUserMail(e.target.value)}} className="w-full h-20 p-4 bg-slate-50 border-slate-400 border placeholder:text-black" placeholder={mail}  name="user_email" />
       </span>
        
        
        <textarea name="message"  value={userMessage} onChange={(e)=>{setUserMessage(e.target.value)}} placeholder={message} className='w-full h-40 p-4 bg-slate-50 border-slate-400 border placeholder:text-black resize-none' />
        <div className='w-full flex items-center justify-end text-white'>
        <input type="submit" value={send} className='w-40 h-full bg-blue-600 p-4 rounded-md' />
        </div>
      </form>


    )
}

export default ContactForm