import React from 'react'

const HalfBanner = ({img,text}) => {
  return (
    <div className={img?'w-full h-[50vh] bg-center !bg-black/30 bg-no-repeat bg-cover relative flex items-center justify-center':'w-full h-[50vh] !bg-black/30 bg-no-repeat bg-cover relative flex items-center justify-center  default'}
    style={img?{backgroundImage:`url(${img})`}:{}}
    >
    <span className='w-full h-full absolute left-0 top-0 bg-black/60'></span>
        <h1 className='text-3xl text-white tracking-widest font-normal z-10'>
            {text?text:''}
        </h1>
    </div>
  )
}

export default HalfBanner