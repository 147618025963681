import React from 'react';

const FileInput = ({ func, value, name, title }) => {
  const handleImageChange = (e) => {
    const selectedFiles = Array.from(e.target.files);
    func([...value, ...selectedFiles]);
  };

  const removeImage = (index) => {
    const updatedImages = value.filter((_, i) => i !== index);
    func(updatedImages);
  };

  return (
    <div className="flex items-start justify-center flex-col p-2 gap-4">
      <div className='w-full flex items-center md:justify-end justify-start'>
      <label className="flex items-center justify-center w-full p-4 md:p-4 md:w-1/3 bg-blue-500 text-white rounded-md" htmlFor={name}>{title}</label>
      <input type="file" id={name} accept="image/*" multiple onChange={handleImageChange} className="hidden" />
      </div>
      <div className="flex w-full items-start justify-start flex-wrap gap-4 ">
        {value.map((file, index) => (
          <div key={index} className=" flex flex-col items-start justify-start gap-2">
            <img src={URL.createObjectURL(file)} alt={`Image-${index}`} className="w-32 h-32 object-cover " />
            <button
              onClick={() => removeImage(index)}
              className="w-full bg-red-500 rounded-md text-white flex items-center justify-center"
            >
              SİL
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default FileInput;
