import { createSlice } from "@reduxjs/toolkit";

const initailState = {
    user: window.localStorage.getItem("userToken") ? window.localStorage.getItem("userToken") : null,
    };

const authSlice = createSlice({
    name: "auth",
    initialState: initailState,
    reducers: {
        login(state, action) {
            state.user = action.payload;
        },
        logout(state) {
            state.user = null;
        },
    },
});

export const { login, logout } = authSlice.actions;
export default authSlice.reducer;