import React from 'react'
import Tilt from 'react-parallax-tilt';

const TiltCard = ({bg1,bg2,folder,reverse}) => {
  return (
    <div className='min-w-[50%] min-h-fit md:mt-32 gap-6 h-full flex-col relative md:flex hidden items-center justify-center mt-16'>
    <Tilt className='w-72 block lg:w-96 top-0 rounded drop-shadow-2xl card shadow-black left-12 -translate-y-1/2 lg:absolute aspect-square object-cover'>
     <div className={`w-72 lg:w-96 top-[40%] bg-center bg-cover rounded drop-shadow-2xl card shadow-black  left-12 -translate-y-1/2 lg:absolute aspect-square  `}
                      style={{backgroundImage:`url(${reverse == true?process.env.REACT_APP_BASE_URL+'uploads/'+folder+'/card/'+bg1:process.env.REACT_APP_BASE_URL+'uploads/'+folder+'/lcard/'+bg1})`}}
      >
     </div>
    </Tilt>
    <Tilt className='w-72 block lg:w-96 top-10  rounded drop-shadow-2xl card shadow-black right-12 -translate-y-1/2 lg:absolute aspect-square object-cover'>
    <div className={'w-72 lg:w-96 top-[40%] rounded drop-shadow-2xl card shadow-black left-12 -translate-y-1/2 lg:absolute aspect-square object-cover default'}
                      style={{backgroundImage:`url(${reverse == true?process.env.REACT_APP_BASE_URL+'uploads/'+folder+'/card/'+bg2:process.env.REACT_APP_BASE_URL+'uploads/'+folder+'/lcard/'+bg2})`}}
                      >
     </div>
    </Tilt>
     
 </div>
  )
}

export default TiltCard