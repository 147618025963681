import React from 'react'
import { useParams } from 'react-router-dom'
import Text from '../../../Components/admin/Text'
import Acor from '../../../Components/admin/Acor';
import { useState, useEffect } from 'react';
import { Editor } from 'primereact/editor';
import Fileİnput from '../../../Components/admin/Fileİnput';
import toast, { Toaster } from 'react-hot-toast';


const Edit = () => {
    const { id } = useParams()
    const [name, setName] = useState('')
    const [title, setTitle] = useState('')
    const [subtitle, setSubtitle] = useState('')
    const [description, setDescription] = useState('')
    const [content, setContent] = useState('')
    const [bottomContent, setBottomContent] = useState('')
    const [video, setVideo] = useState('')
    const [slayt, setSlayt] = useState([])
    const [preSlayt, setPreSlayt] = useState([])
    const [banner, setBanner] = useState([])
    const [preBanner, setPreBanner] = useState([])
    const [kart, setKart] = useState([])
    const [preKart, setPreKart] = useState([])
    const [galeri, setGaleri] = useState([])
    const [preGaleri, setPreGaleri] = useState([])
    const [back, setBack] = useState([])
    const [preBack, setPreBack] = useState([])

    useEffect(() => {
        document.title = 'Proje Düzenle'
        const formdata = new FormData()
        formdata.append('action', 'selectProject')
        formdata.append('id', id)
        fetch(process.env.REACT_APP_BASE_URL + 'selectFrontdata.php', {
            method: 'POST',
            body: formdata
        }).then(res => res.json()).then(res => {
            Object.keys(res).map((key, index) => {
                setName(res[key].name)
                setTitle(res[key].title)
                setSubtitle(res[key].subtitle)
                setDescription(res[key].mainContent)
                setContent(res[key].midleContent)
                setBottomContent(res[key].bottomContent)
                setVideo(res[key].link)
                setPreSlayt(res[key].slayt.split(','))
                setPreBanner(res[key].banner.split(','))
                setPreKart(res[key].kart.split(','))
                setPreGaleri(res[key].galeri.split(','))
                setPreBack(res[key].arkaplan.split(','))
                

            })
        })


    


    }, [])
    const removeImage = (func,index,item) =>{
          func((prev)=>{
          
            const formdata = new FormData()
            formdata.append('action', 'deleteImage')
            formdata.append('id',id )
            formdata.append('name', name)
            
            formdata.append('image', item)

            fetch(process.env.REACT_APP_BASE_URL + 'projects.php', {
                method: 'POST',
                body: formdata
            }).then(res => res.json()).then(res => {
                if (res.status != 200) {
                    toast.error(res.message)
                } else {
                    toast.success(res.message)
                    const updatedImages = prev.filter((_, i) => i !== index);
                    return updatedImages
                }
            })
           
        }
        )
      };


    const submitHandel = () => {
        const formdata = new FormData()
        formdata.append('action', 'updateProject')
        formdata.append('id', id)
        formdata.append('name', name)
        formdata.append('title', title)
        formdata.append('subtitle', subtitle)
        formdata.append('mainContent', description)
        formdata.append('midleContent', content)
        formdata.append('bottomContent', bottomContent)
        formdata.append('link', video)
        for (let i = 0; i < slayt.length; i++) {
            formdata.append('slayt[]', slayt[i])
        }
        for (let i = 0; i < banner.length; i++) {
            formdata.append('banner[]', banner[i])
        }
        for (let i = 0; i < kart.length; i++) {
            formdata.append('kart[]', kart[i])
        }
        for (let i = 0; i < galeri.length; i++) {
            formdata.append('galeri[]', galeri[i])
        }
        for (let i = 0; i < back.length; i++) {
            formdata.append('arkaplan[]', back[i])
        }

        fetch(process.env.REACT_APP_BASE_URL + 'projects.php', {
            method: 'POST',
            body: formdata
        }).then(res => res.json()).then(res => {
            if (res.status != 200) {
                toast.error(res.message)
            } else {
                toast.success(res.message)
            }
        })
    }

    return (
        <div className='w-full flex items-start  gap-2 justify-start min-h-screen'>
            <Toaster position='top-left' />
            <div className='w-full h-screen flex-col flex items-start justify-start px-6 py-4 bg-white border-r overflow-y-scroll border-black'>
                <p className='w-full text-left text-2xl p-1 py-4 border-b border-black mb-2 '>Yazı Alanları</p>
                
                <Acor title='Yazı Alanları'  >
                    <Acor title={'Tekli yazılar'}>
                        
                        <Text title={'Proje Adı'} func={setName} value={name}></Text>
                        <Text title={'Başlık'} func={setTitle} value={title}></Text>
                        <Text title={'Alt başlık'} func={setSubtitle} value={subtitle}></Text>

                    </Acor>
                    <Acor title={'İçerik bilgileri'}>

                        <Acor title={'Açıklama'}>
                            <Editor className='min-h-full' onTextChange={(e) => { setDescription(e.htmlValue) }} value={description}></Editor>
                        </Acor>
                        <Acor title={'Orta içerik'}>
                            <Editor className='min-h-full' onTextChange={(e) => { setContent(e.htmlValue) }} value={content} ></Editor>
                        </Acor>
                        <Acor title={'Son içerik'}>
                            <Editor className='min-h-full' onTextChange={(e) => { setBottomContent(e.htmlValue) }} value={bottomContent}></Editor>
                        </Acor>
                        <Text title={'Video linki'} func={setVideo} value={video}></Text>

                    </Acor>

                </Acor>
                <p className='w-full text-left text-2xl p-1 py-4 border-b border-black  mb-2'>Resim Alanları</p>
                <Acor title={'Resimler'} >
                    <Acor title={'Slayt Resimleri'}>
                        <Fileİnput func={setSlayt} value={slayt} name='slayt' title='Slayt Resimleri' />
                       {
                        preSlayt && <div className='w-full flex  items-start justify-start gap-2 flex-wrap'>
                                {
                                    preSlayt != '' ? preSlayt.map((item, index) => {
                                        return (
                                        <div key={index} className=" flex flex-col items-start justify-start gap-2 p-2">
                                        <img src={process.env.REACT_APP_BASE_URL + 'uploads/projects/'+name+'/slayt/' + item} className='w-32 aspect-square object-cover' />
                                        <button
                                          onClick={() => removeImage(setPreSlayt,index,item)}
                                          className="w-full bg-red-500 rounded-md text-white flex items-center justify-center"
                                        >
                                          SİL
                                        </button>
                                      </div>)
                                    }):null

                                }
                        </div>
                       }
                    </Acor>
                    <Acor title={'Banner Resimi'}>
                        <Fileİnput func={setBanner} value={banner} name='banner' title='Slayt Resimleri' />
                        {
                        preBanner && <div className='w-full flex  items-start justify-start gap-2 flex-wrap'>
                                {
                                    preBanner != '' ? preBanner.map((item, index) => {
                                        return (
                                        <div key={index} className=" flex flex-col items-start justify-start gap-2 p-2">
                                        <img src={process.env.REACT_APP_BASE_URL + 'uploads/projects/'+name+'/banner/' + item} className='w-32 aspect-square object-cover' />
                                        <button
                                          onClick={() => removeImage(setPreBanner,index,item)}
                                          className="w-full bg-red-500 rounded-md text-white flex items-center justify-center"
                                        >
                                          SİL
                                        </button>
                                      </div>)
                                    }):null

                                }
                        </div>
                       }
                    </Acor>
                    <Acor title={'Kart Resimleri'}>
                        <Fileİnput func={setKart} value={kart} name='kart' title='Slayt Resimleri' />
                        {
                        preKart && <div className='w-full flex  items-start justify-start gap-2 flex-wrap'>
                                {
                                    preKart != '' ? preKart.map((item, index) => {
                                        return (
                                        <div key={index} className=" flex flex-col items-start justify-start gap-2 p-2">
                                        <img src={process.env.REACT_APP_BASE_URL + 'uploads/projects/'+name+'/kart/' + item} className='w-32 aspect-square object-cover' />
                                        <button
                                          onClick={() => removeImage(setPreKart,index,item)}
                                          className="w-full bg-red-500 rounded-md text-white flex items-center justify-center"
                                        >
                                          SİL
                                        </button>
                                      </div>)
                                    }):null

                                }
                        </div>
                       }
                    </Acor>
                    <Acor title={'Galeri Resimleri'}>
                        <Fileİnput func={setGaleri} value={galeri} name='galeri' title='Slayt Resimleri' />
                        {
                        preGaleri && <div className='w-full flex  items-start justify-start gap-2 flex-wrap'>
                                {
                                    preGaleri != '' ? preGaleri.map((item, index) => {
                                        return (
                                        <div key={index} className=" flex flex-col items-start justify-start gap-2 p-2">
                                        <img src={process.env.REACT_APP_BASE_URL + 'uploads/projects/'+name+'/galeri/' + item} className='w-32 aspect-square object-cover' />
                                        <button
                                          onClick={() => removeImage(setPreGaleri,index,item)}
                                          className="w-full bg-red-500 rounded-md text-white flex items-center justify-center"
                                        >
                                          SİL
                                        </button>
                                      </div>)
                                    }):null

                                }
                        </div>
                       }
                    </Acor>
                    <Acor title={'Arkaplan Resim'}>
                        <Fileİnput func={setBack} value={back} name='back' title='Slayt Resimleri' />
                        {
                        preBack && <div className='w-full flex  items-start justify-start gap-2 flex-wrap'>
                                {
                                    preBack != '' ? preBack.map((item, index) => {
                                        return (
                                        <div key={index} className=" flex flex-col items-start justify-start gap-2 p-2">
                                        <img src={process.env.REACT_APP_BASE_URL + 'uploads/projects/'+name+'/arkaplan/' + item} className='w-32 aspect-square object-cover' />
                                        <button
                                          onClick={() => removeImage(setPreBack,index,item)}
                                          className="w-full bg-red-500 rounded-md text-white flex items-center justify-center"
                                        >
                                          SİL
                                        </button>
                                      </div>)
                                    }):null

                                }
                        </div>
                       }
                    </Acor>


                </Acor>

                <div className='w-full flex items-center justify-end'>
                    <button onClick={()=>{submitHandel()}} className='w-full lg:w-32 hover:bg-indigo-500  bg-indigo-600 text-white p-2 rounded'>Kaydet</button>
                </div>
            </div>

        </div>
    )
}

export default Edit