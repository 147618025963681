import React, { useEffect, useMemo, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import Translate from '../../Helper/Translate'
import { useSelector } from 'react-redux'
const ProjectCard =  ({data}) => {
    const [desc,setDesc] = useState()
    const [bg,setBg] = useState('')
    const code = useSelector(state => state.Language.language)

    useEffect(() => {
        setBg(data.slayt.split(',')[0])
        
        setDesc(data.mainContent.replace(/(<([^>]+)>)/gi, ""))
        return () => {
            console.log('unmount');
        }
    }, [])
    useMemo(async () => {
        if(code == 'tr'){

            setDesc(data.mainContent.replace(/(<([^>]+)>)/gi, ""))
            
        }else{
            const res = await Translate(data.mainContent,'tr',code)
            setDesc(res.data.translations[0].translatedText.replace(/(<([^>]+)>)/gi, ""))
        }
        return () => {
            console.log('unmount');
        }
    }, [code])
  return (
    <Link to={'/projects/'+data.id} className='w-full flex items-center justify-start flex-col min-h-[24rem]
     shadow-xl shadow-black/00 hover:shadow-black/20 transition-all
     rounded-md overflow-hidden cursor-pointer 
     '>
        <div
         style={{backgroundImage:`url(${process.env.REACT_APP_BASE_URL}uploads/projects/${data.name}/slayt/${bg})`,backgroundSize:'cover',backgroundPosition:'center'}}
         className='w-full min-h-[12.5rem] '>
        </div>
        <div className='w-full flex-1  flex flex-col items-start justify-start py-4 gap-4 px-6'>
            <h1 className='text-xl pl-2 font-semibold tracking-widest'>
                {
                    data && data.name
                }
            </h1>
            <h1  className='text-slate-500 line-clamp-3'>
               {
                desc
               }
            </h1>
        </div>


    </Link>
  )
}

export default ProjectCard