import React, { useEffect, useMemo ,useState} from 'react'
import TiltCard from './TiltCard';


const AboutCard = ({btn = true,reverse = false,card,folder,sideTitle,sideText,sideHeader,compName,send}) => {
    const [c,setCard] = useState([])
    useEffect(() => {
        console.log(c)
        card && setCard(card.split(','))
        return () => {
            
        }
    }
    , [card])
  return (
    <div className='w-full h-[100vh] flex flex-col items-center justify-center p-6 md:py-12 bg-white'>
    <h1 className='md:text-6xl text-3xl md:font-light tracking-widest  w-full text-left px-12 pt-12  text-black'>{sideTitle}</h1>
    
    <div className={reverse == true?'w-full h-full gap-1 flex items-center justify-center py-6 flex-col md:flex-row-reverse':'w-full h-full gap-1 flex items-center justify-center py-6 flex-col md:flex-row'}>
   
        <TiltCard reverse={reverse} folder={folder} bg1={c[0]} bg2={c[1]}/>

    
        <div className='min-w-[50%] h-full flex flex-col items-start justify-start px-12'>
            <div className='w-full text-center flex items-end justify-start  gap-2'>
                <span className='flex-1  border-b-[3px] border-blue-500'></span>
                <h1 className='text-3xl text-slate-400 uppercase tracking-widest font-light '>
                    {compName}
                </h1>
            </div>
            <h1 className='text-3xl py-4  pt-16 text-left font-bold'>
               {sideHeader}
            </h1>
            <p className='text-2xl  text-left font-light'>
                {sideText}
            </p>
            <a href='/about' className={btn == true?'p-4 block text-center tracking-wider w-48 text-2xl bg-blue-500 rounded text-white mt-12':'hidden'}>{send}</a>

        </div>
    </div>
</div>
  )
}

export default AboutCard