import React from 'react'
import ProjectCard from './Cards/ProjectCard'
const Project = ({data}) => {


  return (
    <div className='w-full min-h-[50vh] md:px-12 px-6 py-6 overflow-y-scroll h-auto grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 place-items-center gap-6 '>
        {
            data && data.map((item,index)=>{
                return(
                    <ProjectCard data={item} key={index} index={index}></ProjectCard>
                )
            }
            )
        }
    </div>
  )
}

export default Project