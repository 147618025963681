import React, { useEffect, useMemo } from 'react'
import { BsFacebook, BsInstagram, BsTwitter } from 'react-icons/bs'
import { BsPhone } from 'react-icons/bs'
import { CiMail } from 'react-icons/ci'
import { Link } from 'react-router-dom'
import Translate from '../Helper/Translate'
import { useSelector } from 'react-redux'

const Footer = () => {

    const [logo, setLogo] = React.useState('')
    const [project, setProject] = React.useState([])
    const [contact, setContact] = React.useState([])
    const [projectTitle, setProjectTitle] = React.useState('Projelerimiz')
    const [contactTitle, setContactTitle] = React.useState('İletişim')
    const [homeTitle, setHomeTitle] = React.useState('Anasayfa')
    const [aboutTitle, setAboutTitle] = React.useState('Hakkımızda')
    const [pageTitle, setPageTitle] = React.useState('Sayfalar')

    const code = useSelector(state => state.Language.language)
    useEffect(() => {
        const formdata = new FormData()
        formdata.append('action', 'select')
        fetch(process.env.REACT_APP_BASE_URL + 'logo.php', {
            method: 'POST',
            body: formdata
        }).then(res => res.json()).then(res => {
            if (res.path != '') {
                setLogo(res.path)
            } else {
                setLogo('')
            }
        })

        const formdata2 = new FormData()
        formdata2.append('action', 'selectTopProject')
        fetch(process.env.REACT_APP_BASE_URL + 'selectFrontdata.php', {
            method: 'POST',
            body: formdata2
        }).then(res => res.json()).then(res => {
            setProject(res)
        })
        const formdata3 = new FormData()
        formdata3.append('action', 'contact')
        fetch(process.env.REACT_APP_BASE_URL + 'selectFrontdata.php', {
            method: 'POST',
            body: formdata3
        }).then(res => res.json()).then(res => {
            setContact(res)
        })

    }, [])

    useMemo(async () => {
        if (code != 'tr') {

            const Prt  = await Translate('Projelerimiz','tr',code)
            const Pt = await Translate('Sayfalar','tr',code)
            const Ct = await Translate('İletişim','tr',code)
            const Ht = await Translate('Anasayfa','tr',code)
            const At = await Translate('Hakkımızda','tr',code)
            
            setProjectTitle(Prt.data.translations[0].translatedText)
            setContactTitle(Ct.data.translations[0].translatedText)
            setPageTitle(Pt.data.translations[0].translatedText)
            setHomeTitle(Ht.data.translations[0].translatedText)
            setAboutTitle(At.data.translations[0].translatedText)


        } else {

        }
    }, [code])



    return (
        <div className='w-full h-auto py-8 bg-[#030e22] flex flex-col lg:flex-row items-center justify-between'>
            <div className='w-1/3  flex flex-col items-center justify-center gap-8 '>
                <span>
                    {
                        logo != '' ? <img src={process.env.REACT_APP_BASE_URL + 'uploads/logo/' + logo} alt="" className='h-20' /> : null
                    }
                </span>
                <span className='w-auto mx-auto flex flex-col items-center justify-between gap-4'>
                    <span className='flex flex-col lg:flex-row items-center justify-center gap-0.5'>
                        <BsPhone size={18} className='text-white text-sm' />
                        <a className='text-white  text-center lg:text-left w-full' href={`tel:${contact['phone']}`}>{contact['phone']}</a>
                    </span>
                    <span className='flex flex-col lg:flex-row items-center justify-center gap-1'>
                        <CiMail size={18} className='text-white text-sm' />
                        <a className='text-white text-center lg:text-left w-full' href={`mailto:${contact['mail']}`}>{contact['mail']}</a>
                    </span>
                </span>
                <span className='w-full flex items-center justify-center gap-4 text-left'>
                    <a href={contact['facebook']}>
                        <BsFacebook className='text-white text-sm' />
                    </a>
                    <a href={contact['instagram']}>
                        <BsInstagram className='text-white text-sm' />
                    </a>
                    <a href={contact['twitter']}>
                        <BsTwitter className='text-white text-sm' />
                    </a>
                </span>
            </div>
            <div className='flex-1 flex-col lg:flex-row flex px-12  items-center lg:items-start justify-end gap-6'>
                <div className='   h-full  flex items-center justify-between'>
                    <div className='flex  flex-col items-center justify-start gap-4 p-6'>
                        <h1 className='text-white  text-center text-xl tracking-widest'>
                            {pageTitle}
                        </h1>
                        <span className=' items-start justify-start flex flex-col'>
                            <ul className=' flex flex-col items-center text-center  justify-center gap-2'>
                                <Link to={`/`} className='text-slate-500 hover:text-white cursor-pointer text-center text-sm'>{homeTitle}</Link>
                                <Link to={`/about`} className='text-slate-500 hover:text-white cursor-pointer text-center text-sm'>{aboutTitle}</Link>
                                <Link to={`/projects`} className='text-slate-500 hover:text-white cursor-pointer text-center text-sm'>{projectTitle}</Link>
                                <Link to={`/contact`} className='text-slate-500 hover:text-white cursor-pointer text-center text-sm'>{contactTitle}</Link>
                            </ul>
                        </span>
                    </div>
                </div>
                <div className='   h-full  flex items-center justify-between'>
                    <div className='flex  flex-col items-center justify-center gap-4 p-6'>
                        <h1 className='text-white  text-center text-xl tracking-widest'>
                            {projectTitle}
                        </h1>
                        <span className=' items-start justify-start flex flex-col'>
                            <ul className=' flex flex-col items-center text-center  justify-center gap-2'>
                                {
                                    project.map((item, index) => {
                                        return (
                                            <Link to={`/projects/${item.id}`} key={index} className='text-slate-500 hover:text-white cursor-pointer text-center text-sm'>{item.title}</Link>
                                        )
                                    })

                                }
                            </ul>
                        </span>
                    </div>
                </div>
                <div className='   h-full  flex items-center justify-between'>
                    <div className='flex  flex-col items-center justify-start gap-4 p-6'>
                        <h1 className='text-white  text-center text-xl tracking-widest'>
                            {contactTitle}
                        </h1>
                        <div className=' w-full grid grid-cols-2 place-items-center'>
                            <a className='text-white text-left w-full' href={`tel:${contact['phone']}`}>
                                <BsPhone size={32} className='text-white text-sm' />
                            </a>
                            <a className='text-white text-left w-full' href={`mailto:${contact['mail']}`}>
                                <CiMail size={32} className='text-white text-sm' />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Footer